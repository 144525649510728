import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import axios from 'axios'
import { Box } from '@mui/system'
import { format} from 'date-fns'
import { useFetch } from '../../hook/useFetch'
import { zhTW } from 'date-fns/locale'
import { useQuery } from '../../hook/useQuery'

const Clockin = () => {
    // const {hash} = useParams()
    const hash = useQuery().get('hash')
    console.log(hash)
    const navigate = useNavigate()
    const {data,refetch,error} = useFetch(process.env.REACT_APP_API_ADDRESS+'/clockin/clockin_time/'+hash)
    const [errorMsg,setErrorMsg] = React.useState('')
    async function clockin (hash,time,action) {
        try {
            await axios.post(process.env.REACT_APP_API_ADDRESS+'/clockin',{hash,time,action},{withCredentials:true})
            refetch()
        } catch (error) {
            setErrorMsg(error.response.data.message)
        }
    } 
    // const text = React.useMemo(()=>data.length?getStatusText(data[0]):null,[data])
    // function getStatusText (data) {
    //     if(!data){return}
    //     const {
    //         clockin_time,
    //         prev_clockin_time,
    //         prev_clockin_action
    //     } = data
    //     let status
    //     let res = {
    //         clockin_time:formatTime(clockin_time)
    //     }
    //     const today = new Date()
    //     const lastTime = prev_clockin_time&&new Date(prev_clockin_time)
    //     if(!lastTime){
    //         return {
    //             ...res,
    //             prev_clockin_time:'無',
    //             status:'新的一天，開心上班吧'
    //         }
    //     }else{
    //         if(isSameDay(today,lastTime)){
    //             status = {
    //                 clockin:'您正在上班',
    //                 clockout:'您已經上過班',
    //             }[prev_clockin_action]
    //         }else{
    //             status = {
    //                 clockin:'您昨日忘記打卡，工時將不被計算，請向老闆另外紀錄該帳目！',
    //                 clockout:'新的一天，開心上班吧',
    //             }[prev_clockin_action]
    //         }
    //         return {
    //             ...res,
    //             prev_clockin_time:formatTime(prev_clockin_time),
    //             status
    //         }
    //     }
    //     function formatTime (time) {
    //         return format(new Date(time),'yy/MM/dd HH:mm (iiii)',{locale:zhTW})
    //     }
    // }
    React.useEffect(()=>{
        if(error){
            // console.log(error)
            navigate('/clockin')
        }
    },[data])
  return (
    <div
    style={{
        width:'100%',
        height:'100%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    }}
    >
        {
        <Dialog
        open={errorMsg?true:false}
        onClose={()=>{
            setErrorMsg('')
        }}
        >
            <DialogTitle>錯誤</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {errorMsg}
                </DialogContentText>
            </DialogContent>
        </Dialog>
        }
        <Box
        component='div'
        className="clockin-btn"
        sx={{
            width:'80%',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            gap:10
        }}
        >
            {data?.[0]&&
            <>
            <Typography
            >
            {data[0].status}
            <br/>
            <br/>
            前次打卡時段：{format(new Date(data[0].prev_clockin_time),'yy/MM/dd HH:mm (iiii)',{locale:zhTW})}
            <br/>
            {data[0]?.availAction&&`本次打卡時段：${format(new Date(data[0].clockin_time),'yy/MM/dd HH:mm (iiii)',{locale:zhTW})}`}
            </Typography>
            {data[0]?.availAction&&
            <Button
                variant='contained'
                size='large'
                color={data[0].availAction==='clockin'?'success':'error'}
                onClick={()=>{
                    clockin(hash,data?.[0]?.clockin_time,data[0].availAction)
                }}
                children={data[0].availAction==='clockin'?'打卡上班':'打卡下班'}
            />
            }
            {/* <Button
                variant='contained'
                color='error'
                onClick={()=>clockin(hash,data?.[0]?.clockin_time,'clockout')}
                children='打卡下班'
            /> */}
            </>
            }
        </Box>
        
    </div>
  )
}

export default Clockin