import { Box, Button, ButtonGroup, ClickAwayListener, Collapse, Dialog, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText, ListSubheader, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import { format } from 'date-fns';
import { Add, AddCircle, Cancel, CheckCircle, Edit, ExpandLess, ExpandMore, RemoveCircle } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import axios from 'axios';
import { useFetch } from '../../hook/useFetch';
import { getOrderStatus } from '../../tool/getOrderStatus';
import DoubleCheckButton from '../doubleCheckButton/DoubleCheckButton.jsx'
import StyledSubheader from '../styledSubheader/StyledSubheader.jsx'
import { useLocation } from 'react-router-dom';

const StyledEditableListItem = ({input,output,type,label,multiline,disabled}) => {
    const [edit,setEdit] = React.useState(false)
    const [update,setUpdate] = React.useState(null)
    const handleUpdate = async () => {
        try {
            await output(update)
            setEdit(false)
            setUpdate(null)
        } catch (error) {
            console.log(error)
        }
    }
    const format = (value) => {
        if(type==='number'){
            return value.match(/[1-9][0-9]*$/)?.[0]
        }
        return value
    }
    return(
        <ListItem
        sx={{
            justifyContent:'flex-start'
        }}
        >
            <Box
            sx={{
                flex:1,
                display:'flex'
            }}
            >
            {label&&
            <ListItemText
            sx={{
                wordBreak:'keep-all',
                whiteSpace:'nowrap',
                flex:1
            }}
            primary={label}
            />
            }
            {
            edit?
            <TextField
            sx={{flex:1}}
            size='small'
            hiddenLabel
            type={type}
            multiline={multiline}
            value={
                update===null?
                input:
                (update||'')
            }
            onChange={(e)=>{
                const value = format(e.target.value)
                setUpdate(value)
            }}
            />:
            <ListItemText
            sx={{flex:1}}
            primary={input||'無'}
            />
            }
            </Box>
            <Box
            sx={{
                flex:1,
                display:'flex',
                justifyContent:'flex-end'
            }}
            >
            {
            edit?
            <>
            <IconButton
            color='success'
            children={<CheckCircle/>}
            onClick={()=>handleUpdate()}
            />
            <IconButton
            color='error'
            onClick={()=>{
                setUpdate(null)
                setEdit(false)
            }}
            children={<Cancel/>}
            />
            </>:
            <IconButton
            disabled={disabled}
            color='primary'
            onClick={()=>setEdit(true)}
            children={<Edit/>}
            />
            }
            </Box>
        </ListItem>
    )
}
const StyledSelect = ({label,menu,value,multiple,onChange}) =>{
    return(
        <FormControl
        // variant='standard'
        size='small'
        sx={{
            minWidth:120,
            m:1
        }}
        >
            <InputLabel 
            id='select-time-label'>
                {label}
            </InputLabel>
            <Select
            labelId='select-time-label'
            id='select-time'
            label={label}
            value={value||(multiple?[]:'')}
            onChange={onChange}
            multiple={multiple}
            >
                <MenuItem 
                value={''}
                children='無安排'
                />
                {menu.map((v)=>
                <MenuItem 
                key={v}
                value={v}
                >{v}</MenuItem>
                )
                }
            </Select>
        </FormControl>
    )
}
const StyledBreakFastList = ({input,output,disabled,person}) => {
    const defaultItem = {
            table:[],
            // roomNumber:'',
            time:'',
            drink:[
                {name:'人數',quantity:person},
                {name:'熱咖啡',quantity:0},
                {name:'熱紅茶',quantity:0},
                {name:'冰咖啡',quantity:0},
                {name:'冰紅茶',quantity:0},
                {name:'熱鮮奶',quantity:0},
                {name:'冰鮮奶',quantity:0},
                {name:'果汁',quantity:0},
                {name:'冰果汁',quantity:0},
            ]
        }
    const [update,setUpdate] = React.useState(JSON.parse(JSON.stringify(input)))
    const hasChanged = React.useMemo(()=>{
        return update?
                JSON.stringify(input)!==JSON.stringify(update):
                false
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[input,update])
    const [openIndex,setOpenIndex]= React.useState(null)
    const openItem =       openIndex?
                            (
                            update?
                                update[openIndex[0]]:
                                input[openIndex[0]]
                            ):
                            null
    const openDrink =       openItem?.drink?.[openIndex?.[1]]||
                            null
    const timePlan = ['08:00','08:30','09:00']
    const tablePlan = new Array(7).fill().map((v,i)=>1+i)
    // const roomPlan = [
    //     '101',
    //     '102',
    //     '201',
    //     '202',
    //     '203',
    //     '204',
    //     '205',
    // ]
    function handleDrinkNumberButton(index1,index2,difference){
        let newUpdate = JSON.parse(JSON.stringify(update||input))
        const result = newUpdate[index1].drink[index2].quantity+=difference
        if(result>=0){
            setUpdate(newUpdate)
        }              
    }
    function handleDrinkNumber (_value,add) {
        if(!openIndex){return}
        let value = add?
                        openDrink.quantity+add:
                        _value.match(/[1-9][0-9]*$/)?.[0]
        value = value>0?value:0
        setUpdate((prev)=>{
            // let newUpdate = prev?
            //                 JSON.parse(JSON.stringify(input)):
            //                 prev.concat()
            let newUpdate = prev.concat()
            newUpdate[openIndex[0]].drink[openIndex[1]].quantity = value
            return newUpdate
        })
    }
    function handleDrinkName (name,check) {
        if(
            check&&(
            openItem.drink.filter((d)=>d.name===name).length>1||
            name.length<=0)
            ){
            throw new Error('not a propper name')
        }
        setUpdate((prev)=>{
            let newUpdate = prev.concat()
            newUpdate[openIndex[0]].drink[openIndex[1]].name = name 
            return newUpdate
        })
    }
    function handleDrinkPosition (step) {
        if(!openIndex){return}
        const nextIndex = (openItem.drink.length+openIndex[1]+step)%openItem.drink.length
        setUpdate((prev)=>{
            // let newUpdate = prev?
            //                 JSON.parse(JSON.stringify(input)):
            //                 prev.concat()
            let newUpdate = prev.concat()
            newUpdate[openIndex[0]].drink[nextIndex] = 
                newUpdate[openIndex[0]].drink.splice(
                    openIndex[1],
                    1,
                    newUpdate[openIndex[0]].drink[nextIndex]
                    )[0]
            return newUpdate
        })
        setOpenIndex([openIndex[0],nextIndex])
    } 
    function handleValue (value,index) {
        setUpdate((prev)=>{
            let newUpdate = prev.concat()
            newUpdate[index] = {...newUpdate[index],...value}
            return newUpdate
        })
    }
    return(
        <>
            <Dialog
            open={openItem?true:false}
            sx={{
                height:'80%',
                '.MuiDialog-container':{alignItems:'flex-end'}
            }}
            onClose={()=>{
                try {
                    handleDrinkName(openDrink.name,true)
                    setOpenIndex(null)
                } catch (error) {
                    console.log(error)
                }
            }}
            >
                <Paper
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',
                    height:'fit-content',
                    p:1,
                    marginTop:'auto',
                    gap:1
                }}
                >
                    <Box>
                    {
                    <TextField
                    sx={{
                        flex:2,
                        wordBreak:'keep-all',
                        whiteSpace:'nowrap'
                    }}
                    value={openDrink?.name||''}
                    onChange={(e)=>handleDrinkName(e.target.value)}
                    />
                    }
                    </Box>
                    <Paper
                    sx={{
                        display:'flex',
                        p:1
                    }}
                    >
                        <IconButton
                        sx={{
                            width:'fit-content'
                        }}
                        onClick={()=>handleDrinkNumber(null,1)}
                        children={<AddCircle/>}
                        />
                        <TextField
                        sx={{
                            flex:1
                        }}
                        size='small'
                        hiddenLabel
                        value={openDrink?.quantity||0}
                        onChange={(e)=>handleDrinkNumber(e.target.value,null)}
                        />
                        <IconButton
                        sx={{
                            width:'fit-content'
                        }}
                        onClick={()=>handleDrinkNumber(null,-1)}
                        children={<RemoveCircle/>}
                        />
                    </Paper>
                    <Box
                    sx={{
                        width:1,
                        display:'flex',
                        gap:1,
                        p:1
                    }}
                    >
                        <Button
                        variant='contained'
                        children='<前移'
                        onClick={()=>handleDrinkPosition(-1)}
                        />
                        <Button
                        variant='contained'
                        children='後移>'
                        onClick={()=>handleDrinkPosition(1)}
                        />
                        <Button
                        variant='contained'
                        color='error'
                        children='刪除'
                        onClick={()=>{
                            setUpdate((prev)=>{
                                let newUpdate = prev.concat()
                                newUpdate[openIndex[0]].drink = openItem.drink.filter((v,i)=>i!==openIndex[1])
                                return newUpdate
                            })
                            setOpenIndex(null)
                        }}
                        />
                        <Button
                        variant='contained'
                        color='success'
                        children='OK'
                        onClick={()=>{
                            setOpenIndex(null)
                        }}
                        />
                    </Box>
                </Paper>
            </Dialog>
            <List
            subheader={<StyledSubheader children='早餐安排'/>}
            >
            {
            (update||input||[]).map((item,index1)=>{
                return(
                    <ListItem
                    key={`breakfast-item-${index1}`}
                    >
                        <Paper
                        sx={{
                            width:'100%',
                            display:'flex',
                            flexDirection:'column',
                            overflow:'auto'
                        }}
                        >
                            <Box
                            sx={{
                                display:'flex',
                                justifyContent:'flex-end',
                                width:1
                            }}
                            >
                            <DoubleCheckButton
                            actionName='確定刪除'
                            action={()=>
                                setUpdate((prev)=>{
                                    let newUpdate = prev?prev.concat():input.concat()
                                    newUpdate = newUpdate.filter((v,i)=>i!==index1)
                                    return newUpdate
                                })
                            }
                            >
                            <IconButton
                            disabled={disabled}
                            color='error'
                            sx={{
                                height:1,
                                aspectRatio:1/1
                            }}
                            children={<Cancel/>}
                            />
                            </DoubleCheckButton>
                            </Box>
                            <StyledSelect
                            label='選擇桌位'
                            menu={tablePlan}
                            value={item.table}
                            multiple={true}
                            onChange={(e)=>{
                                const value = e.target.value
                                handleValue({table:value.includes('')?[]:value},index1)
                            }}
                            />
                            {/* <StyledSelect
                            label='選擇房號'
                            menu={roomPlan}
                            value={item.roomNumber}
                            onChange={(e)=>{
                                handleValue({roomNumber:e.target.value},index1)
                            }}
                            /> */}
                            <StyledSelect
                            label='選擇時段'
                            menu={timePlan}
                            value={item.time}
                            onChange={(e)=>{
                                handleValue({time:e.target.value},index1)
                            }}
                            />
                            <List
                            subheader={
                            <StyledSubheader 
                            sx={{
                                width:1,
                                display:'flex',
                                justifyContent:'space-between'
                            }}
                            children='早餐飲料'
                            />
                            }
                            sx={{
                                width:1,
                                display:'flex',
                                flexDirection:'column',
                                gap:1
                            }}
                            >
                            {
                            item.drink
                            .map(({name,quantity},index2)=>
                                <Box
                                component='div'
                                key={name}
                                sx={{
                                    width:'100%',
                                    boxSizing:'border-box',
                                    pl:1,
                                    pr:1,
                                }}
                                >
                                <ListItem
                                sx={{
                                    border:'1px solid',
                                    borderRadius:1,
                                    p:0,
                                    alignItems:'stretch'
                                }}
                                >
                                    <ListItemButton
                                    onClick={()=>{
                                        if(!update){
                                            setUpdate(JSON.parse(JSON.stringify(input)))
                                        }
                                        setOpenIndex([index1,index2])
                                    }}
                                    >
                                    {`${name}: ${quantity}`}
                                    </ListItemButton>
                                    <Button
                                    sx={{
                                        m:1
                                    }}
                                    variant='outlined'
                                    children='-'
                                    onClick={()=>{
                                        handleDrinkNumberButton(index1,index2,-1)
                                    }}
                                    />
                                    <Button
                                    sx={{
                                        m:1
                                    }}
                                    variant='outlined'
                                    children='+'
                                    onClick={()=>{
                                        handleDrinkNumberButton(index1,index2,1)
                                    }}
                                    />
                                    {/* <Button
                                    variant='outlined'
                                    children={`${name}: ${quantity}`}
                                    onClick={()=>{
                                        if(!update){
                                            setUpdate(JSON.parse(JSON.stringify(input)))
                                        }
                                        setOpenIndex([index1,index2])
                                    }}
                                    /> */}

                                </ListItem>
                                </Box>
                            )}
                                <ListItem
                                sx={{
                                    width:'fit-content',
                                    p:1
                                }}
                                >
                                    <IconButton
                                    children={<AddCircle/>}
                                    onClick={()=>{
                                        let nextIndex = update[index1]?.drink.length
                                        setUpdate((prev)=>{
                                            let newUpdate = prev?
                                                            prev.concat():
                                                            JSON.parse(JSON.stringify(input))
                                            newUpdate[index1]?.drink.push({name:`新飲料${nextIndex+1}`,quantity:1})
                                            return newUpdate
                                        })
                                        setOpenIndex([index1,nextIndex])
                                    }}
                                    />
                                </ListItem>
                            </List>
                        </Paper>
                    </ListItem>
                )
            })
            }
            <ListItem>
                <Button
                disabled={disabled}
                startIcon={<Add/>}
                children='新增'
                onClick={()=>setUpdate((prev)=>{
                    let newUpdate = prev?
                                    prev.concat():
                                    input?
                                        JSON.parse(JSON.stringify(input)):
                                        []  
                    newUpdate.push(defaultItem)
                    return newUpdate
                })}
                />
            </ListItem>
            {hasChanged&&!disabled&&
            <ListItem
            sx={{
                gap:1
            }}
            >
                <Button
                variant='contained'
                color='warning'
                fullWidth
                children='更新'
                onClick={()=>{
                    try {
                        output(
                            // removeNoneDrink(update)
                            update
                            )
                        // function removeNoneDrink (update) {
                        //     const newUpdate = update.map((i)=>{
                        //         const newDrink = i.drink.filter(({quantity})=>quantity>0)
                        //         return({...i,drink:newDrink})
                        //     })
                        //     return newUpdate
                        // }
                        setUpdate(null)
                    } catch (error) {
                        console.log(error)
                    }
                }}
                />
                <Button
                variant='contained'
                children='取消'
                onClick={()=>setUpdate(JSON.parse(JSON.stringify(input)))}
                />
            </ListItem>
            }
            </List>
        </>
    )
}
const PrintButton = ({htmlContent,...props}) => {
    const handlePrint = () => {
        const ws = 'height=600,width=800'
        const html = `  <html>
                            <head>
                            <title>Print</title>
                            <body>
                            ${htmlContent}
                            </body>
                            </head>
                        </html>
                        `
        const pw = window.open('','Print',ws)
        pw.document.write(html)
        pw.onload = ()=>{
            pw.print()
            setTimeout(()=>pw.close(),500)
        }
        pw.document.close()
    }
    return(
        <DoubleCheckButton
        actionName={'無紙化，你還要列印?'}
        action={()=>
            handlePrint()
        }
        >
        <Button
        {...props}
        children='列印'
        // onClick={()=>handlePrint()}
        />
        </DoubleCheckButton>
    )
}
const OrderView = ({orderId,close=()=>{}}) => {
    axios.defaults.withCredentials = true
    const location = useLocation()
    // const {hasCompleted} = location?.state||false
    const query = React.useMemo(()=>({orders:[orderId]}),[orderId])
    const {
        data,
        refetch
    } = useFetch(`${process.env.REACT_APP_API_ADDRESS}/order/?'}`,query)
    // const {
    //     data,
    //     refetch
    // } = useFetch(`${process.env.REACT_APP_API_ADDRESS}/order/${hasCompleted?'completed/?':'?'}`,query)
    const order = data[0]||{}
    const {
        _id,
        bookingId,
        orderType,
        orderer,
        mobile,
        address,
        startDate,
        endDate,
        night,
        veganType,
        veganNumber,
        totalPerson,
        roomNumber,
        addPersonRoomNumber,
        roomPrice,
        discountId,
        discountPrice,
        totalPrice,
        depositBankAccount,
        lastFiveDigitsOfBankAccountFromSender,
        actualDepositAmount,
        otherRequest,
        isDepositApproved,
        noteFromOperator,
        breakfast
    } = order
    const hasCompleted =  orderType === 'completed'
    const hasPendingRequest = React.useMemo(()=>
    otherRequest?.some(({requestStatus})=>requestStatus==='pending')
    //eslint-disable-next-line react-hooks/exhaustive-deps    
    ,[data])
    const {text:statusText} = getOrderStatus(order)
    const defaultDoubleCheck = {
        confirmOrCancel:false,
        delete:false,
    }
    const [doubleCheck, setDoubleCheck] = React.useState(defaultDoubleCheck)
    const [message,setMessage] = React.useState('')
    const [mode,setMode]=React.useState(location.state?.mode||0)
    const htmlContent = React.useMemo(()=>data[0]?
        [
            ['編號',_id],
            ['狀態',statusText],
            ['姓名',orderer],
            ['電話',mobile],
            ['地址',address],
            ['入住',format(new Date(startDate),'yyyy/MM//dd')],
            ['退房',format(new Date(endDate),'yyyy/MM//dd')],
            ['天數',night+'天'],
            ['房號',roomNumber.join('/')],
            ['間數',roomNumber.length+'間'],
            ['人數',totalPerson+'人'],
            ['折扣',discountId?`${discountId}:${discountPrice}元`:'無'],
            ['總價',totalPrice+'元'],
            ['匯入帳號',depositBankAccount],
            ['帳號後五碼',lastFiveDigitsOfBankAccountFromSender||'無'],
            ['實匯金額',actualDepositAmount+'元'],
            ['尚欠餘款',totalPrice-actualDepositAmount+'元'],
            ['其他需求',otherRequest.map(({request,requestStatus,requestReply})=>`需求:${request}<br>回復:(${requestStatus})${requestReply||''}`).join('<br>')]
        ].map((val)=>{
            const key = `<div class>${val[0]}</div>`
            const value = `<div>${val[1]}</div>`
            return `<div 
                    class='print-order'
                    style=  "
                            display:flex;
                            justify-content:space-between;
                            border-bottom:1px solid black;
                            " 
                    >${key}${value}</div>`
        }).join(''):[]
        //eslint-disable-next-line react-hooks/exhaustive-deps
    ,[data])
    const disableFunc = (hasCompleted||bookingId)?true:false
    const StyledListItem = ({icon,label,value,size='normal',...props})=>{
    const fontSize = {
        large:36,
        normal:undefined
    }
    return(
    <ListItem>
    <Grid
    container
    sx={{
        borderBottom:'1px solid black'
    }}
    {...props}
    >
        <Grid 
        item 
        sx={{mr:1}}
        >
            <Typography
            variant='subtitle1'
            sx={{
                wordBreak:'keep-all'
            }}
            >{label}</Typography>
        </Grid>
        <Grid 
        item 
        xs
        sx={{
            display:'flex',
            justifyContent:'flex-end'
        }}
        >
            <Typography
            variant='body1'
            fontSize={fontSize[size]}
            sx={{
                // wordBreak:'keep-all',
                wordWrap:'normal',
                whiteSpace:'nowrap'
            }}
            >{value}</Typography>
        </Grid>
    </Grid>
    </ListItem>
    )
    }
    const StyledRqListItem = ({
        _id,
        request,
        requestStatus,
        requestReply,
        refetch,
        ...props
    }) => {
        if(requestStatus==='pending'){
            requestStatus = null
        }
        const [open,setOpen] = React.useState(false)
        const [edit,setEdit] = React.useState(false)
        const [reply,setReply] = React.useState(requestReply)
        const [status,setStatus] = React.useState(requestStatus)
        const reset = () => {
            setEdit(false)
            setReply(requestReply)
            setStatus(requestStatus)
        }
        const replyRequest = async () => {
            try {
                await axios.put(
                    process.env.REACT_APP_API_ADDRESS+'/setting/reply_request/'+_id,
                    {
                        requestStatus:status,
                        requestReply:reply||''
                    }
                    )
                refetch()
                reset()
            } catch (error) {
                console.log(error)
            }
        }
        return(
            <ListItem
            {...props}
            >
                <Paper
                elevation={3}
                sx={{width:'100%'}}
                >
                    <ul style={{listStyleType:'none'}}>
                        <ListItem
                        sx={{
                            pr:4
                        }}
                        >
                            <ListItemText>{request}</ListItemText>
                            <ListItemSecondaryAction>
                                <IconButton
                                onClick={()=>setOpen(!open)}
                                >
                                    {open?<ExpandLess/>:<ExpandMore/>}
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Collapse 
                        component='div'
                        in={open}
                        sx={{pr:2,pl:2}}
                        >
                            <Grid container>
                                <Grid 
                                item
                                xs
                                sx={{mr:1}}
                                >
                                    <TextField
                                    name='requestReply'
                                    id={`input-request-reply-${_id}`}
                                    disabled={hasCompleted}
                                    variant={requestReply?'standard':'outlined'}
                                    multiline
                                    label='回覆'
                                    fullWidth
                                    value={reply||''}
                                    onChange={(e)=>{
                                        if(!edit){
                                            setEdit(true)
                                        }
                                        setReply(e.target.value)
                                    }}
                                    />
                                </Grid>
                            </Grid>
                            </Collapse>
                        <ListItem
                        sx={{
                            pr:4
                        }}
                        >
                            <ButtonGroup>
                                {
                                    [
                                        ['fullyApproved','同意'],
                                        ['partiallyApproved','部分同意'],
                                        ['rejected','不同意'],
                                    ].map((v,i)=>
                                    <Button
                                    disabled={disableFunc}
                                    key={i}
                                    variant={
                                        status===v[0]?
                                        'contained':
                                        'outlined'
                                    }
                                    onClick={()=>{
                                        if(!edit){
                                            setEdit(true)
                                        }
                                        setStatus(v[0])
                                    }}
                                    >{v[1]}</Button>
                                    )
                                }
                            </ButtonGroup>
                        </ListItem>
                        {edit&&
                        <ListItem>
                            <IconButton
                            color='primary'
                            disabled={
                                !status||
                                (
                                    requestStatus===status&&
                                    requestReply===reply
                                )
                            }
                            onClick={()=>replyRequest()}
                            >
                                <CheckCircle/>
                            </IconButton>
                            <IconButton
                            color='error'
                            onClick={()=>reset()}
                            >
                                <Cancel/>
                            </IconButton>
                        </ListItem>
                        }
                    </ul>
                </Paper>
            </ListItem>
        )
    }
    const handleDelete = async () =>{
        try {
            try {
                await axios.delete(process.env.REACT_APP_API_ADDRESS+'/order/'+_id,
                {
                    data:message?
                            {
                                message:message
                            }:
                            undefined
                })
            } catch (error) {
                console.log('一般刪除出現問題，改強制刪除')
                throw error
                // await axios.post(process.env.REACT_APP_API_ADDRESS+'/setting/clean_orders',{orderIds:[_id]})
            }
            close()
        } catch (error) {
            console.log(error)
        }
    }
    const handleUpdate = async (form) => {
        try {
            await axios.put(process.env.REACT_APP_API_ADDRESS+`/order/${_id}`,form)
            refetch()
        } catch (error) {
            throw error
        }
    }
    const approveOrder = async (approved) => {
        try {
            await axios.post(process.env.REACT_APP_API_ADDRESS+'/setting/approve_order',
            {
                orderId,
                approved
            })
            refetch()
        } catch (error) {
            console.log(error)
        }
    }
  return (
    data.length>0&&
    <Box 
    component='div'
    sx={{
        height:'100vh',
        display:'flex',
        flexDirection:'column',
        gap:1,
        overflow:'auto'
        }}
    >
        <Box
        sx={{
            p:1,
            display:'flex',
            justifyContent:'space-between'
        }}
        >
        <Button
        onClick={()=>close()}
        startIcon={<ArrowBackIosIcon/>}
        >
        返回
        </Button>
        <ButtonGroup>
            <Button
            variant={mode===0?'contained':'outlined'}
            onClick={()=>setMode(0)}
            children='明細'
            />
            <Button
            variant={mode===1?'contained':'outlined'}
            color={hasPendingRequest?'warning':undefined}
            onClick={()=>setMode(1)}
            children={'備註'+(hasPendingRequest?' !':'')}
            />
            <Button
            variant={mode===2?'contained':'outlined'}
            color={noteFromOperator?'warning':undefined}
            onClick={()=>setMode(2)}
            children={'安排'+(noteFromOperator?' !':'')}
            />
            <PrintButton
            variant={'outlined'}
            htmlContent={htmlContent}
            />
        </ButtonGroup>
        </Box>
        {mode===0&&
        <List 
        subheader={
            <ListSubheader 
            component="div"
            disableSticky={true}
            >
                訂單資料
            </ListSubheader>
            }
        >
        <StyledListItem
        label='編號'
        value={_id}
        />
        <StyledListItem
        label='狀態'
        value={
            bookingId?
            `BOOKING訂單:${bookingId}`:
            statusText
        }
        />
        <StyledListItem
        label='姓名'
        value={orderer}
        />
        <StyledListItem
        label='電話'
        value={mobile}
        />
        <StyledListItem
        label='地址'
        value={address}
        />
        <StyledListItem
        label='日期'
        value={[startDate,endDate].map((date)=>format(new Date(date),'yyyy/MM/dd')).join('-')+`(${night}晚)`}
        />
        <StyledListItem
        label='房號'
        value={roomNumber.join('/')+`(${roomNumber.length}間)`}
        />
        {addPersonRoomNumber.length>0&&
        <StyledListItem
        label='加人房號'
        value={addPersonRoomNumber.join('/')+`(${addPersonRoomNumber.length}間)`}
        />
        }
        <StyledListItem
        label='人數'
        value={totalPerson}
        />
        {discountId&&<>
        <StyledListItem
        label='原價'
        value={roomPrice}
        />
        <StyledListItem
        label='折扣'
        value={`${discountId}:${discountPrice}`}
        />
        </>
        }
        <StyledListItem
        label='總價'
        value={totalPrice}
        />
        <StyledListItem
        label='匯入帳號'
        value={depositBankAccount}
        />
        <StyledListItem
        label='帳號後五碼'
        value={lastFiveDigitsOfBankAccountFromSender||'未提供'}
        />
        <StyledListItem
        label='實匯金額'
        value={actualDepositAmount}
        />
        <StyledListItem
        label='尚欠餘款'
        value={totalPrice-actualDepositAmount}
        size='large'
        />
        {!disableFunc&&
        <>
        <ListItem>
            <ClickAwayListener
            onClickAway={()=>{
                if(doubleCheck.confirmOrCancel){
                    setDoubleCheck((prev)=>({...prev,confirmOrCancel:false}))
                }
            }}
            >
            <Button 
            variant='contained' 
            fullWidth
            color={isDepositApproved?'warning':'success'}
            onClick={()=>{
                if(!doubleCheck.confirmOrCancel){
                    setDoubleCheck((prev)=>({...prev,confirmOrCancel:true}))
                }else{
                    if(isDepositApproved){
                        approveOrder(false)
                    }else{
                        approveOrder(true)
                    }
                    setDoubleCheck((prev)=>({...prev,confirmOrCancel:false}))
                }
            }}
            >
                {
                    isDepositApproved?
                    '取消確認':
                    '完成確認'
                }
                {
                    doubleCheck.confirmOrCancel&&
                    ' 確定?'
                }
            </Button>
            </ClickAwayListener>
        </ListItem>
        {!isDepositApproved&&
        <ClickAwayListener
            onClickAway={()=>{
                if(doubleCheck.delete){
                    setDoubleCheck((prev)=>({...prev,delete:false}))
                }
            }}
            >
        <ListItem
        sx={{
            flexDirection:'column',
            gap:2
        }}
        >
            <Button 
            variant='contained' 
            fullWidth
            color='error'
            onClick={()=>{
                if(!doubleCheck.delete){
                    setDoubleCheck((prev)=>({...prev,delete:true}))
                }else{
                    handleDelete()
                    setDoubleCheck((prev)=>({...prev,delete:false}))
                }
            }}
            >
                刪除訂單
                {
                    doubleCheck.delete&&
                    ' 確定?'
                }
            </Button>
            <Collapse
                in={doubleCheck.delete}
                sx={{width:1}}
            >
                    <TextField
                    fullWidth
                    label='寄信告知內容'
                    onChange={(e)=>setMessage(e.target.value)}
                    />
            </Collapse>
        </ListItem>
        </ClickAwayListener>
        }
        </>
        }
        </List>
        }
        {mode===1&&
        <>
        <List
        subheader={<ListSubheader children='特殊餐食'/>}
        >
        {veganNumber>0&&
        <StyledListItem
        label='素食'
        value={
            {
                'ovo-lacto':'蛋奶素',
                'vegan':'純素',
                'none':'無'
            }[veganType]+`／${veganNumber}人`
        }
        />
        }
        </List>
        <List 
        subheader={
            <ListSubheader 
            component="div"
            disableSticky={true}
            >
                其他需求
            </ListSubheader>
            }
        >
            {
                otherRequest.map((request)=>{
                return(<StyledRqListItem 
                    key={request._id}
                    refetch={refetch}
                    {...request}
                    />)
                }
                )
            }
        </List>
        </>
        }
        {mode===2&&
        <>
        {
        <StyledBreakFastList
        disabled={hasCompleted}
        person={totalPerson}
        input={breakfast}
        output={(update)=>{
            handleUpdate({breakfast:update})
        }}
        />
        }
        <List
        subheader={<ListSubheader children='內部註記'/>}
        >
            <StyledEditableListItem
            disabled={hasCompleted}
            input={noteFromOperator}
            output={(update)=>{
                handleUpdate({noteFromOperator:update})
            }}
            multiline={true}
            />
        </List>
        </>
        }
    </Box>

  )
}

export default OrderView