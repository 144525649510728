import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import axios from 'axios';
import { UserContext } from '../../context/UserContext';
import { FormControlLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer, Switch } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useFetch } from '../../hook/useFetch';
import DoubleCheckButton from '../doubleCheckButton/DoubleCheckButton';

export default function ButtonAppBar () {
    const {dispatch,user} = React.useContext(UserContext)
    const {
      isAdmin,
      userId
    } = user
    const regex = /(?:^\/)(\w+)/
    const location = useLocation()
    let mapping = 
    [
    {label:'餐食',url:'meal_arrange'},
    {label:'房務',url:'housekeeping'},
    {label:'員工',url:'staff'+(isAdmin?'':`/${userId}`)},
    ]
    if(isAdmin){
      mapping = [
        {label:'訂單',url:'order'},
        {label:'優惠',url:'discount'},
        {label:'報表',url:'report'},
        {label:'日期',url:'calendar'},
        {label:'房間',url:'room'},
        {label:'設定',url:'setting'},
        {label:'打卡',url:'clockin'},
      ].concat(mapping)
    }
    const url = location.pathname.match(regex)?.[1]||'/'
    const page = mapping.find((i)=>i.url===url)?.label
    const navigate = useNavigate()
    const {data,refetch} = useFetch(process.env.REACT_APP_API_ADDRESS+'/setting/?query[]=isBookingPaused')
    const [open, setOpen] = React.useState(false);
    const logout = async () => {
        try {
          await axios.post(process.env.REACT_APP_API_ADDRESS+'/auth/logout')
          dispatch({
            type:'LOGOUT'
          })
        } catch (error) {
          
        }
      }
    const handleIsBookingPaused = async (checked) => {
      axios.defaults.withCredentials = true
      try {
        await axios.put(process.env.REACT_APP_API_ADDRESS+'/setting',{isBookingPaused:checked})
        refetch()
      } catch (error) {
        console.log(error)
      }
    }
    const list = (anchor) => (
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={()=>setOpen(!open)}
      >
        <List>
          {mapping.map((item, index) => (
            <ListItem key={item.url} disablePadding>
              <ListItemButton
              onClick={()=>{
                // setPage(item.label)
                navigate(item.url)
              }}
              >
                <ListItemIcon>
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    )
  return (
    <div
    style={{

    }}
    >
    <Box sx={{ 
      flexGrow: 1
      }}>
      <AppBar 
      color='default'
      position="fixed" 
      sx={{
        top:0,
        left:0,
        zIndex:1,
        overflow:'scroll',
        }}>
        <Toolbar
        sx={{
          minWidth:'360px'
        }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ 
              mr: 2,
              height:1,
              aspectRatio:1/1
            }}
            onClick={()=>setOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {page}
          </Typography>
          {
            isAdmin&&
            typeof data?.isBookingPaused === 'boolean'&&
          <DoubleCheckButton
          actionName={'訂房'+(data.isBookingPaused?'開啟':'暫停')}
          action={()=>handleIsBookingPaused(!data.isBookingPaused)}
          >
          <FormControlLabel
          sx={{
            mr:2
          }}
          label={data.isBookingPaused===false?'訂房開啟':'訂房暫停'}
          labelPlacement="start"
          control={
            <Switch
            color='success'
            checked={data?.isBookingPaused===false}
            // onChange={
              // (e)=>handleIsBookingPaused(e.target.checked)
            // }
            />
          }
          />
          </DoubleCheckButton>
          }
          <Button 
          variant='outlined'
          color="inherit"
          onClick={()=>logout()}
          >登出</Button>
        </Toolbar>
      </AppBar>
        <Toolbar />
        <SwipeableDrawer
          anchor='left'
          open={open}
          onClose={()=>setOpen(false)}
          onOpen={()=>setOpen(true)}
        >
            {list()}
        </SwipeableDrawer>
    </Box>
    <div
    id='button-app-bar-container'
    style={{
      overflow:'scroll',
      height:'calc(100vh - 60px)'
    }}
    >
    <Outlet/>
    </div>
    </div>
  );
}