import React from 'react'
import { Box, ClickAwayListener, Collapse, Divider, FormControl, Grid, IconButton, List, ListItem, ListItemText, Switch, Tab, Tabs, TextField, Typography } from '@mui/material'
import { Cancel, CheckCircle, Delete, Edit } from '@mui/icons-material'
import axios from 'axios'
import { useFetch } from '../../hook/useFetch'
import StyledSubheader from '../../component/styledSubheader/StyledSubheader'
import AddItemButton from '../../component/addItemButton/AddItemButton'
import DoubleCheckButton from '../../component/doubleCheckButton/DoubleCheckButton'
import CustomImageList from '../../component/customImageList/CustomImageList'

const StyledListItem = ({
    label,
    hiddenLabel,
    id,
    value,
    refetch,
    multiline,
    column=true,
    onUpdate,
    onDelete,
    validate=()=>{},
    ...props
}) => {
    const [edit,setEdit] = React.useState(null)
    const [update,setUpdate] = React.useState()
    const [error,setError] = React.useState(null)
    const isSwitch = typeof value === 'boolean'
    const closeEditing = () => {
        setEdit(null)
        setUpdate(null)
        setError(null)
    }
    const EditIconButton = () => {
        const EADBtn = <>
                        <IconButton
                        onClick={()=>{
                            setEdit(true)
                        }}
                        >
                            <Edit/>
                        </IconButton>
                        {
                        onDelete&&
                        <DoubleCheckButton
                        icon={<Delete/>}
                        actionName='刪除'
                        action={
                            async()=>{
                                try {
                                    await onDelete()
                                } catch (error) {
                                    setError(error)
                                }
                            }}
                        />
                        }
                        </>
        const DCBtn =   <>
                        <IconButton
                        onClick={
                            async () => {
                               try {
                                    if(update){
                                        if(update.length===0){
                                            setError('不能空白')
                                        }
                                        validate(update)
                                        onUpdate(update)
                                        closeEditing()
                                    }else{
                                        closeEditing()
                                    }
                               } catch (error) {
                                    setError(error)
                               } 
                            }}
                        >
                            <CheckCircle
                            color='success'
                            />
                        </IconButton>
                        <IconButton
                        onClick={()=>
                            closeEditing()
                        }
                        >
                            <Cancel
                            color='error'
                            />
                        </IconButton>
                        </>

        return(
            isSwitch?
            <DoubleCheckButton
            actionName={`確定${value?'開啟':'暫停'}?`}
            action={()=>onUpdate(!value)}
            >
            <FormControl
            hiddenLabel
            >
            <Switch
                id={id}
                checked={value}
            />
            </FormControl>
            </DoubleCheckButton>:
            <Box
            sx={{
                display:'flex',
                height:'fit-content'
            }}
            children={edit?DCBtn:EADBtn}
            />
        )
    }
    return(
        <ClickAwayListener
        onClickAway={()=>{
            closeEditing()
        }}
        >
        <div>
        <ListItem
        {...props}
        >
            <Box
            sx={{
                width:1,
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center'
            }}
            >
            {
            edit?
            <TextField
            id={id}
            label={hiddenLabel?undefined:label}
            hiddenLabel={hiddenLabel}
            // InputLabelProps={{shrink: false}}
            // size='small'
            fullWidth
            multiline={multiline}
            defaultValue={value}
            onChange={(e)=>setUpdate(e.target.value)}
            />:
            <Grid
            container
            >
                <Grid item
                xs
                >
                    <Typography
                    align='left'
                    sx={{
                        width:'fit-content',
                        wordBreak:'keep-all',
                        color:'primary.main',
                        fontWeight:'bold'
                    }}
                    children={label}
                    />
                </Grid>
                {
                !isSwitch&&
                <Grid 
                item 
                xs={column?12:true}
                sx={{
                    dispaly:'flex',
                    justifyContent:'flex-end'
                }}
                >
                    <Typography
                    children={
                        multiline?
                        value?.slice(0,30)+'...':
                        value
                    }
                    />
                </Grid>
                }
            </Grid>
            }
            <Box>
                <EditIconButton
                />
            </Box>
            </Box>
        </ListItem>
        <Collapse
        in={error?true:false}
        sx={{pr:2,pl:2}}
        >
            <Typography
            color='error'
            >{error?.message}</Typography>
        </Collapse>
        <Divider
            component="li"
        />
        </div>
        </ClickAwayListener>
    )
}
const Setting = () => {
    const {data,refetch}=useFetch(process.env.REACT_APP_API_ADDRESS+'/setting')
    const {
        // isBookingPaused,
        depositBankAccount,
        depositBeforeHours,
        depositRate,
        intro,
        rule,
        email,
        otherInfo,
        photo,
        smsText
    } = data||{}
    const handleUpdate = async function (req) {
        try {
            await axios.put(
                process.env.REACT_APP_API_ADDRESS
                +'/setting',
                req,
                {
                    withCredentials: true
                }
                )
            refetch()
        } catch (error) {
            throw error
        }
    }
    const makeDeepUpdate = (preValues,newValue,index,key) => {
         let value = JSON.parse(JSON.stringify(preValues))
         value[index] = key?
                        {
                            ...value[index],
                            [key]:newValue
                        }:
                        newValue
        return value
    }
    const removeItem = (values,ObjKey,index) => new Promise((resolve,reject)=>{
        try {
        axios.put(
                    process.env.REACT_APP_API_ADDRESS
                    +'/setting',
                    {
                        [ObjKey]:values.filter((value,key)=>key!==index)
                    },
                    {
                        withCredentials: true
                    }
                ).then((result)=>{
                    resolve(result)
                })
        } catch (error) {
            reject(error)
        }
    })
    const [tab,setTab] = React.useState(0)
  return (
    <div 
    className='setting'
    >
    <Tabs
    value={tab}
    onChange={(e,v)=>setTab(v)}
    variant='scrollable'
    scrollButtons
    allowScrollButtonsMobile
    >
    <Tab label='基本設定'/>
    <Tab label='網站內容'/>
    <Tab label='首頁照片'/>
    </Tabs>
    {
    Object.keys(data).length>0&&
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            mb:4
            }}>
            {tab===0&&
                <>
                    {/* <List
                    subheader={<StyledSubheader>系統狀態</StyledSubheader>}
                    >
                        <StyledListItem
                        label={isBookingPaused?'目前系統暫停':'目前系統上線'}
                        id={'isBookingPaused'}
                        value={isBookingPaused}
                        onUpdate={(value)=>handleUpdate({isBookingPaused:value})}
                        />
                    </List> */}
                    <List
                    subheader={<StyledSubheader>訂金設定</StyledSubheader>}
                    >
                    <StyledListItem
                    label={'收匯帳號'}
                    id='depositBankAccount'
                    value={depositBankAccount}
                    onUpdate={(value)=>handleUpdate({depositBankAccount:value})}
                    />
                    <StyledListItem
                    label={'訂金百分比'}
                    id='depositRate'
                    value={depositRate}
                    validate={(value)=>{
                        if(value>1||value<0){
                            throw new Error('需在0-1之間')
                        }
                    }}
                    onUpdate={(value)=>handleUpdate({depositRate:value})}
                    />
                    <StyledListItem
                    label={'匯款截止時限（小時）'}
                    id='depositBeforeHours'
                    value={depositBeforeHours}
                    onUpdate={(value)=>handleUpdate({depositBeforeHours:value})}
                    />
                    <StyledListItem
                    label={'薪水設定（時薪）'}
                    id='wageByHour'
                    value={data?.wageByHour||0}
                    onUpdate={(value)=>handleUpdate({wageByHour:value})}
                    />
                    <StyledListItem
                    label={'薪水設定（日薪)'}
                    id='wageByDay'
                    value={data?.wageByDay||0}
                    onUpdate={(value)=>handleUpdate({wageByDay:value})}
                    />
                    <StyledListItem
                    label={'薪水設定（月薪)'}
                    id='wageByMonth'
                    value={data?.wageByMonth||0}
                    onUpdate={(value)=>handleUpdate({wageByMonth:value})}
                    />
                    <StyledListItem
                        label={'簡訊內容'}
                        id={'smsText'}
                        value={smsText}
                        multiline={true}
                        onUpdate={(value)=>handleUpdate({smsText:value})}
                    />
                    </List>
                    <List
                    subheader={<StyledSubheader>信箱設定</StyledSubheader>}
                    >
                        {email.map((value,index)=>
                        <StyledListItem
                        key={'信箱設定-'+index}
                        id={`email-${index}`}
                        hiddenLabel={true}
                        value={value}
                        multiline={true}
                        onUpdate={(value)=>{
                            const newEmail = email.concat()
                            newEmail[index] = value
                            handleUpdate({email:newEmail})
                        }}
                        onDelete={async()=>{
                            try {
                            await removeItem(email,'email',index)
                            refetch()
                            } catch (error) {
                                throw error
                            }
                        }
                        }
                        />)
                        }
                    </List>
                    <AddItemButton
                        text={'新增信箱'}
                        onClick={async()=>{
                            try {
                                await axios.put(
                                    process.env.REACT_APP_API_ADDRESS+'/setting',
                                    {'email':[...email,'yung.fong6688@gmail.com']},
                                    {
                                        withCredentials:true
                                    }
                                )
                                refetch()
                            } catch (error) {
                                console.log(error)
                            }
                        }}
                    />
                </>
            }
            {tab===1&&
                <>
                    <List
                    subheader={<StyledSubheader>網站內容</StyledSubheader>}
                    >
                        <StyledListItem
                        label={'首頁介紹'}
                        id={'intro'}
                        value={intro}
                        multiline={true}
                        onUpdate={(value)=>handleUpdate({intro:value})}
                        />
                    </List>
                    <List
                    subheader={<StyledSubheader>入住規則</StyledSubheader>}
                    >
                        {rule.map((value,index)=>
                        <StyledListItem
                        key={'入住規則-'+index}
                        id={`rule-${index}`}
                        hiddenLabel={true}
                        value={value}
                        multiline={true}
                        onUpdate={(value)=>{
                            const newRule = rule.concat()
                            newRule[index] = value
                            handleUpdate({rule:newRule})
                        }}
                        onDelete={async()=>{
                            try {
                            await removeItem(rule,'rule',index)
                            refetch()
                            } catch (error) {
                                throw error
                            }
                        }
                        }
                        />)
                        }
                    </List>
                    <AddItemButton
                        text={'新增規則'}
                        onClick={async()=>{
                            try {
                                await axios.put(
                                    process.env.REACT_APP_API_ADDRESS+'/setting',
                                    {'rule':[...rule,'新增']},
                                    {
                                        withCredentials:true
                                    }
                                )
                                refetch()
                            } catch (error) {
                                console.log(error)
                            }
                        }}
                    />
                    <List
                    subheader={<StyledSubheader>其他資訊</StyledSubheader>}
                    >
                        {otherInfo.map((value,index)=>{
                        return(
                        <div 
                        key={index}
                        >
                        <List>
                        <ListItem>
                            <ListItemText
                                primary={'資訊'+index}
                            />
                            <DoubleCheckButton
                            icon={<Delete/>}
                            actionName='刪除'
                            action={
                            async()=>{
                                try {
                                await removeItem(otherInfo,'otherInfo',index)  
                                refetch()
                                } catch (error) {
                                    console.log(error)
                                }
                            }}
                            />
                        </ListItem>
                        <StyledListItem
                        label={'標題'}
                        id={'title'}
                        value={value.title}
                        column={true}
                        onUpdate={(value)=>handleUpdate({otherInfo:makeDeepUpdate(otherInfo,value,index,'title')})}
                        multiline={true}
                        sx={{pl:6}}
                        />
                        <StyledListItem
                        label={'圖片網址'}
                        id={'img'}
                        value={value.img}
                        column={true}
                        onUpdate={(value)=>handleUpdate({otherInfo:makeDeepUpdate(otherInfo,value,index,'img')})}
                        multiline={true}
                        sx={{pl:6}}
                        />
                        <StyledListItem
                        label={'網頁網址'}
                        id={'url'}
                        value={value.url}
                        column={true}
                        onUpdate={(value)=>handleUpdate({otherInfo:makeDeepUpdate(otherInfo,value,index,'url')})}
                        multiline={true}
                        sx={{pl:6}}
                        />
                        </List>
                        </div>
                        )})
                        }
                    </List>
                    <AddItemButton
                    sx={{mb:1}}
                    text='新增資訊'
                    onClick={
                        async()=>{
                        try {
                            await axios.put(
                                process.env.REACT_APP_API_ADDRESS+'/setting',
                                {otherInfo:[
                                    ...otherInfo,
                                    {
                                        title:'新增',
                                        img:'新增',
                                        url:'新增'
                                    }
                                ]},
                                {
                                    withCredentials:true
                                }
                            )
                            refetch()
                        } catch (error) {
                            console.log(error)
                        }
                    }}
                    />
                </>
            }
            {tab===2&&
                <CustomImageList
                photo={photo}
                refetch={refetch}
                onUpdate={async(val)=>await handleUpdate({photo:val})}
                onDelete={async(ids)=>{
                    await axios.delete(process.env.REACT_APP_API_ADDRESS+'/setting/photo/?',
                    {
                        withCredentials:true,
                        params:{id:ids}
                    })
                }}
                />
            }
            </Box>
    }  
    </div>
  )
}

export default Setting