import { useTheme } from '@emotion/react'
import { Add, Delete, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Checkbox, Container, FormControlLabel, List, ListItem, ListItemText, ListSubheader, MobileStepper, Paper, Switch, TextField, Typography} from '@mui/material'
import { blue, green, red } from '@mui/material/colors'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {addDays, format, isAfter, isBefore, isSameDay} from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import zhTW from 'date-fns/locale/zh-TW';
import React from 'react'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { zhCN } from 'date-fns/locale';
import DateRangePicker from '../dateRangePicker/DateRangePicker';

const WeekPicker = ({input,output}) => {
    const theme = createTheme({
        palette: {
          weekday: {
            main: green[500]
          },
          weekend: {
            main: blue[500]
          },
          closedDay: {
            main: red[500]
          },
        },
      });
    const modes = [
        {label:'平日定義',key:'weekday'},
        {label:'假日定義',key:'weekend'},
        {label:'公休定義',key:'closedDay'}
    ]
    const [mode,setMode] = React.useState(modes[0])
    const handleClick = (value) => {
        output(
            Object.keys(input).reduce((acc,cur)=>{
            if(cur === mode.key){
                if(!acc[cur].includes(value)){
                return(
                {
                    ...acc,
                    [cur]:acc[cur].concat([value]).sort((a,b)=>a-b)
                }
                )
                }
                return acc
            }else{
                return(
                    {
                        ...acc,
                        [cur]:acc[cur].filter((item)=>item!==value)
                    }
                    )
            }
        },{...input})
        )
    }
    return(
        <ThemeProvider theme={theme}>
        <Paper
        sx={{
            width:1,
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-start',
        }}
        >
        <ButtonGroup
        fullWidth
        variant='text'
        sx={{
            '*':{
                borderRadius:'0px'
            }
        }}
        >
        {
            modes.map((item,index)=>
            <Button
            key={index}
            color={item.key}
            variant={mode.key===item.key?'contained':'outlined'}
            children={item.label}
            onClick={()=>setMode(item)}
            />
            )
        }
        </ButtonGroup>
        <ButtonGroup
        fullWidth
        sx={{
            '*':{
                borderRadius:'0px'
            }
        }}
        >
            {
            [
                [0,'日'],
                [1,'一'],
                [2,'二'],
                [3,'三'],
                [4,'四'],
                [5,'五'],
                [6,'六']].map((value,index)=>{
            const color = Object.keys(input).filter((key)=>input[key].includes(value[0]))[0]
            return(
            <Button
            key={index}
            color={color}
            variant='contained'
            onClick={()=>handleClick(value[0])}
            children={value[1]}
            />)
            }
            )
            }
        </ButtonGroup>
        </Paper>
        </ThemeProvider>    
    )
}
const ClosedDateList = ({input,output}) => {
    const [selection,setSelection] = React.useState([])
    const [newDate,setNewDate] = React.useState(new Date())
    return(
    <LocalizationProvider 
    dateAdapter={AdapterDateFns} 
    adapterLocale={zhTW}
    >
    <Paper
    sx={{width:1}}
    >
        <List
        subheader={
            <div
            style={{
                display:'flex',
                justifyContent:'space-between'
            }}
            >
            <ListSubheader
            children='關閉日期'
            />
            <Button
            variant='contained'
            startIcon={<Delete/>}
            children={`刪除 ${selection.length} 天`}
            color='error'
            onClick={()=>{
                output({...input,closedDate:input.closedDate.filter((value)=>!selection.includes(value))})
                setSelection([])
            }
            }
            />
            </div>
        }
        >
            
            {
                input.closedDate.map((i,index)=>{
                    const checked = selection.includes(i)
                    const date = new Date(i)
                    return(
                        <ListItem
                        key={i}
                        >
                            <Checkbox
                            id={`checkbox-closed-date-${index}`}
                            checked={checked} 
                            onChange={()=>setSelection((prev)=>{
                                if(checked){
                                    return prev.filter((j)=>j!==i)
                                }else{
                                    return prev.concat([i])
                                }
                            })} 
                            />
                            <ListItemText primary={format(date,'yy/MM/dd/iiii',{locale:zhCN})}/>
                        </ListItem>
                    )
                })
            }
            <ListItem
            sx={{
                display:'flex',
                flexDirection:'column',
                alignItems:'flex-start',
                gap:1,
                width:1
            }}
            >
                <DatePicker
                label='新增關閉日期'
                format='yyyy/MM/dd'
                value={newDate}
                onChange={(value)=>setNewDate(new Date(new Date(value).toDateString()))}
                shouldDisableDate={(date)=>input.closedDate.some((cDate)=>isSameDay(new Date(cDate),date))}   
                sx={{
                    flex:2
                }}
                />
                <Button
                sx={{
                    flex:1
                }}
                variant='contained'
                startIcon={<Add/>}
                onClick={()=>{
                if(!input.closedDate.some((v)=>isSameDay(v,newDate))){
                    output(
                        {
                            ...input,
                            closedDate:input.closedDate.concat([newDate]).sort((a,b)=>isBefore(new Date(a),new Date(b))?-1:1)
                        })
                    setNewDate(new Date())
                }
                }}
                children={'新增'}
                />
            </ListItem>
        </List>
    </Paper>
    </LocalizationProvider>
    )
}
const SpecialDateList = ({input,output}) => {
    const [selection, setSelection] = React.useState([])
    const [newItem,setNewItem] = React.useState({
        name:'',
        startDate:new Date(new Date().toDateString()),
        endDate:addDays(new Date(new Date().toDateString()),1)
    })
    return(
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper
            sx={{width:1}}
            >
                <List
                subheader={
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <ListSubheader
                        children='特殊日期'
                        />
                        <Button
                        startIcon={<Delete/>}
                        color='error'
                        variant='contained'
                        children={`刪除 ${selection.length} 項`}
                        onClick={()=>{
                            output(
                                {
                                priceSchema:input.priceSchema.map((room)=>{
                                    return {
                                        ...room,
                                        specialPrice:room.specialPrice.filter(({name})=>!selection.includes(name))
                                    }
                                }),
                                daySchema:{
                                ...input.daySchema,
                                specialDate:input.daySchema.specialDate.filter(({name})=>!selection.includes(name))
                                }
                                }
                            )
                            setSelection([])
                        }}
                        />
                    </div>
                }
                >
                    {
                        input.daySchema.specialDate.map((item,index)=>{
                            const period = [item.startDate,item.endDate].map((date)=>format(new Date(date),'yyyy/MM/dd')).join('-')
                            return(
                                <ListItem
                                key={item.name}
                                >
                                    <Checkbox
                                    id={`checkbox-sp-date-${index}`}
                                    onClick={()=>setSelection((prev)=>{
                                        if(selection.includes(item.name)){
                                            return prev.filter((i)=>i!==item.name)
                                        }else{
                                            return prev.concat([item.name])
                                        }
                                    })}
                                    />
                                    <ListItemText
                                    primary={item.name}
                                    secondary={period}
                                    />
                                </ListItem>
                            )
                        })
                    }
                    <ListItem
                    sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        width:1
                    }}
                    >
                                <Box
                                sx={{
                                    position:'relative',
                                    width:1,
                                    flex:2,
                                    display:'flex',
                                    flexDirection:'column',
                                    alignItems:'flex-start',
                                    gap:(theme)=>theme.spacing(2),
                                    boxSizing:'border-box'
                                }}
                                >
                                    <TextField
                                    label='名稱'
                                    value={newItem.name}
                                    onChange={(e)=>setNewItem((prev)=>({...prev,name:e.target.value}))}
                                    />
                                    <DateRangePicker
                                    input={{
                                        startDate:newItem.startDate,
                                        endDate:newItem.endDate
                                    }}
                                    output={(date)=>{
                                        setNewItem((prev)=>({...prev,...date}))
                                    }}
                                    sx={{
                                        width:1
                                    }}
                                    />
                                    {/* <DatePicker
                                    label='起始日期'
                                    format='yyyy/MM/dd'
                                    onChange={(value)=>setNewItem((prev)=>({...prev,startDate:new Date(new Date(value).toDateString())}))}
                                    />
                                    <DatePicker
                                    label='結束日期'
                                    format='yyyy/MM/dd'
                                    onChange={(value)=>setNewItem((prev)=>({...prev,endDate:new Date(new Date(value).toDateString())}))}
                                    /> */}
                                <Button
                                sx={{
                                    flex:1
                                }}
                                variant='contained'
                                startIcon={<Add/>}
                                children='新增'
                                onClick={()=>{
                                const validate = () =>{
                                    if(
                                        !newItem &&
                                        Object.keys(newItem).length!==3
                                        ){
                                        console.log('節日設定不完整')
                                        return false
                                    }
                                    if(input.daySchema.specialDate.some(({name})=>{
                                            return name===newItem.name
                                        })
                                    ){
                                        console.log('節日重複')
                                        return false
                                    }
                                    if(isBefore(new Date(newItem.endDate),new Date(newItem.startDate))
                                    ){
                                        console.log('日期不正確')
                                        return false
                                    }
                                    return true
                                }
                                    
                                   if(
                                    validate()
                                   ){
                                    const cutIndex = input.daySchema.specialDate.findIndex(({startDate})=>isBefore(new Date(startDate),new Date(newItem.startDate)))
                                    const newSpecialPrice = {
                                                                name:newItem.name,
                                                                price:0,
                                                                canAddPerson:false,
                                                                addPersonPrice:0
                                                            }
                                    output(
                                    {
                                        priceSchema:input.priceSchema.map((item)=>({
                                            ...item,
                                            specialPrice:[
                                                ...item.specialPrice.slice(0,cutIndex+1),
                                                {...newSpecialPrice},
                                                ...item.specialPrice.slice(cutIndex+1)
                                            ]
                                        })),
                                        daySchema:{
                                            ...input.daySchema,
                                            specialDate:[
                                                ...input.daySchema.specialDate.slice(0,cutIndex+1),
                                                newItem,
                                                ...input.daySchema.specialDate.slice(cutIndex+1)
                                            ]
                                        }
                                    }
                                    )
                                    }   
                                }}
                                />
                                </Box>
                    </ListItem>
                </List>
            </Paper>
        </LocalizationProvider>
    )
}
const PriceFields = ({input,output}) => {
    const [multiple,setMultiple] = React.useState(false)
    const [curIndexes,setCurIndexes] = React.useState([0])
    const fields = input[curIndexes[0]] 
    const handleChange = (e,index=null,key) => {
        const id = key
        const value = e.target.type==='number'?
                    (e.target.value.match(/[1-9]{1}[0-9]*$/)?.[0]||0):
                    e.target.checked
        const res = input.map((v,i)=>{
                            let update
                            if(index!==null){
                                const specialPrice = v.specialPrice.map((v,i)=>{
                                    if(i===index){
                                        return {
                                            ...v,
                                            [id]:value
                                        }
                                    }else{
                                        return v
                                    }
                                })
                                update = {
                                            ...v,
                                            specialPrice
                                        }
                            }else{
                                update =    {
                                                ...v,
                                                [id]:value
                                            }
                            }
                            if(curIndexes.length>1&&curIndexes.includes(i)){
                                return {
                                        ...update,
                                        roomNumber:v.roomNumber
                                        }
                            }else if(curIndexes[0]===i){
                                return update
                            }else{
                                return v
                            }
                        })
        output(res)
    }
    return(
        <Paper
        sx={{
            p:2,
            display:'flex',
            flexDirection:'column',
            gap:(theme)=>theme.spacing(2)
        }}
        >
            <FormControlLabel
            label={multiple?'多選':'單選'}
            sx={{
                width:'fit-content'
            }}
            control={
            <Switch
                id='switch-multiple'
                checked={multiple}
                onChange={(e)=>setMultiple(e.target.checked)}
            />}
            />
            <ButtonGroup>
                {
                    input.map(({roomNumber},index)=>{
                    const selected = curIndexes.includes(index)
                    return(
                    <Button
                    key={roomNumber}
                    variant={selected?'contained':'outlined'}
                    onClick={()=>{
                        if(!multiple){
                            setCurIndexes([index])
                        }else if(selected&&curIndexes.length>1){
                            setCurIndexes((prev)=>prev.filter((v)=>v!==index))
                        }else if(!selected){
                            setCurIndexes((prev)=>prev.concat([index]))
                        }
                    }}
                    children={roomNumber}
                    />)
                })
                }
            </ButtonGroup>
            <Typography
                children={'房號:'+fields.roomNumber}
            />
            <FormControlLabel
            label={fields.closed?'關閉':'開啟'}
            sx={{
                width:'fit-content'
            }}
            control={
            <Switch
            id='checkbox-close'
            checked={!fields.closed}
            onChange={(e)=>{
                e.target.checked = !e.target.checked
                handleChange(e,null,'closed')
            }}
            />
            }
            />
            {
            !fields.closed&&
            <>
            <TextField
            label='平日價格'
            id='input-weekday-price'
            type='number'
            value={fields.weekdayPrice}
            onChange={(e)=>handleChange(e,null,'weekdayPrice')}
            />
            <TextField
            label='假日價格'
            id='input-weekend-price'
            type='number'
            value={fields.weekendPrice}
            onChange={(e)=>handleChange(e,null,'weekendPrice')}
            />
            <FormControlLabel
                label='可加人'
                sx={{
                    width:'fit-content'
                }}
                control={
                <Checkbox
                id='checkbox-can-add-person'
                checked={fields.canAddPerson}
                onClick={(e)=>handleChange(e,null,'canAddPerson')}
                />
                }
            />
            {
                fields.canAddPerson&&
                <TextField
                id='checkbox-add-person-price'
                label='加人費用'
                type='number'
                value={fields.addPersonPrice}
                onChange={(e)=>handleChange(e,null,'addPersonPrice')}
                />
            }
            {
                fields.specialPrice.map((item,index)=>{
                    return(
                        <Paper
                        key={item.name}
                        sx={{
                            p:1,
                            ml:2,
                            display:'flex',
                            flexDirection:'column',
                            gap:2,
                            bgcolor:'warning.light'
                        }}
                        >
                        <Typography
                        children={item.name}
                        />
                        <TextField
                        label='價格'
                        id={`sp-date-price-${index}`}
                        type='number'
                        value={item.price}
                        onChange={(e)=>handleChange(e,index,'price')}
                        />
                        <FormControlLabel
                        label='可加人'
                        sx={{
                            width:'fit-content'
                        }}
                        control={
                        <Checkbox
                        id={`checkbox-sp-date-price-${index}`}
                        checked={item.canAddPerson}
                        onClick={(e)=>handleChange(e,index,'canAddPerson')}
                        />          
                        }
                        />
                        {
                            item.canAddPerson&&
                            <TextField
                            id={`sp-add-person-price-${index}`}
                            label='加人費用'
                            type='number'
                            value={item.addPersonPrice}
                            onChange={(e)=>handleChange(e,index,'addPersonPrice')}
                            />
                        }
                        <FormControlLabel
                        label='設定結束日價格'
                        sx={{
                            width:'fit-content'
                        }}
                        control={
                        <Checkbox
                        id={`checkbox-sp-date-price-has-last-day-${index}`}
                        checked={item?.hasLastDayPrice?true:false}
                        onClick={(e)=>handleChange(e,index,'hasLastDayPrice')}
                        />          
                        }
                        />
                        {
                            item?.hasLastDayPrice&&
                            <TextField
                            id={`sp-date-price-has-last-day-${index}`}
                            label='結束日價格'
                            type='number'
                            value={item?.lastDayPrice||0}
                            onChange={(e)=>handleChange(e,index,'lastDayPrice')}
                            />
                        }
                        </Paper>
                    )
                })
            }
            </>}
        </Paper>
    )
}
const PadView = (
    // {type,schema,closePad}
    ) => {
    axios.defaults.withCredentials = true
    const location = useLocation()
    const navigate = useNavigate()
    const {type,schema} = location.state
    const [activeStep,setActiveStep] = React.useState(0)
    const [form,setForm]=React.useState(schema)
    const actionType = {
        new:'建立',
        update:'更新'
    }[type]
    const maxSteps = 4
    const theme = useTheme()
    const setDaySchema = (value) => {
        setForm((prev)=>({...prev,daySchema:value}))
    }
    const handleUpdate = async () => {
        try {
            if(type==='new'){
                await axios.post(process.env.REACT_APP_API_ADDRESS+'/setting/pads',form)
            }else if(type==='update'){
                await axios.put(process.env.REACT_APP_API_ADDRESS+'/setting/pads/'+schema._id,form)
                await axios.put(process.env.REACT_APP_API_ADDRESS+'/setting/calendar',{calendar:[]})
            }
            navigate('/calendar')
        } catch (error) {
            console.log(error)
        }
    }
    const step = (activeStep) => {
        switch(activeStep){
            case 0:
                return(
                <TextField
                label="計畫名稱"
                disabled={type==='update'}
                value={form.name}
                onChange={(e)=>setForm((prev)=>({...prev,name:e.target.value}))}
                />
                )
            case 1:
                return(
                    <List>
                        <ListItem>
                          <WeekPicker
                          input={form.daySchema}
                          output={setDaySchema}
                          />  
                        </ListItem>
                        <ListItem>
                           <ClosedDateList
                           input={form.daySchema}
                           output={setDaySchema}
                           />
                        </ListItem>
                        <ListItem>
                            <SpecialDateList
                            input={{
                                priceSchema:form.priceSchema,
                                daySchema:form.daySchema,
                            }}
                            output={(v)=>{
                                setForm({
                                    ...form,
                                    ...v
                                })
                            }}
                            />
                        </ListItem>
                    </List>
                )
            case 2:
                return(
                        <PriceFields
                        input={form.priceSchema}
                        output={(value)=>{
                            // console.log(value)
                            setForm((prev)=>({
                                ...prev,
                                priceSchema:value
                            }))
                        }}
                        />
                )
            case 3:
                return(
                    <Paper
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        gap:2,
                        p:2
                    }}
                    >
                    <Typography
                    children={`請再次確認並按${actionType}`}
                    />
                    {type==='update'&&
                    <Typography
                    sx={{pl:5,pr:5}}
                    children={'(若計畫已套用，此更新會影響整個日曆，請小心使用。)'}
                    />
                    }
                    <Button
                    variant='contained'
                    size='large'
                    children={actionType}
                    onClick={()=>handleUpdate()}
                    />
                    </Paper>
                )
            default: break
        }
    }
  return (
    <Container
    sx={{
        position:'fixed',
        top:0,
        width:'100vw',
        height:'100vh',
        zIndex:999,
        bgcolor: 'white',
        overflow:'scroll'
    }}
    >
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent:'center',
          mt:1,
          bgcolor: 'background.default'
        }}
      >
        <Typography>{`${actionType}價格計畫`}</Typography>
      </Paper>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button            
            size="small"
            onClick={()=>setActiveStep((prev)=>prev+1)}
            disabled={
                activeStep === maxSteps - 1
            }
          >
            下一步
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button 
          size="small" 
          onClick={()=>{
            if(activeStep === 0){
                navigate(-1)
            }else{
                setActiveStep(activeStep-1)
            }
            }}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            上一步
          </Button>
        }
      />
      {
        step(activeStep)
      }
    </Container>
  )
}

export default PadView