import { Box, ButtonGroup, Checkbox, Dialog, FormControlLabel, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListSubheader, Skeleton, Typography } from '@mui/material'
import {format, isSameDay } from 'date-fns'
import React from 'react'
import CallIcon from '@mui/icons-material/Call';
import MessageIcon from '@mui/icons-material/Message';
import { getOrderStatus } from '../../tool/getOrderStatus';
import { Call, CallRounded, Sms } from '@mui/icons-material';
import DoubleCheckButton from '../doubleCheckButton/DoubleCheckButton';
import axios from 'axios';

const CustomListItem = ({order,onOrderCheck,onOrderClick,smsText}) => {
  const {
    _id,
    orderer,
    night,
    roomNumber,
    totalPerson,
    otherRequest,
    mobile,
    veganNumber,
    userVisitingTimes
  } = order
  const [open,setOpen] = React.useState(false)
  const [checked,setChecked] = React.useState(order.checkedByAdmin)
  const {status,text:statusText,shortenText} = getOrderStatus(order)
  const bgcolor = function(){
    if(
      ['confirming','outdated'].includes(status)||
      otherRequest.some((i)=>i.status==='pending')
    ){
      return 'error.main'
    }else if(status==='pending'){
      return 'warning.main'
    }else{
      return undefined
    }
  }
  const StyledTypo = (props)=>(
    <Typography
     sx={{
      // border:'1px solid black',
      p:0.5,
      wordBreak:'keep-all'
     }}
    >
      {props.children}
    </Typography>
  )
  const handleCheck = async (checked) => {
    axios.defaults.withCredentials = true
    try {
        await axios.put(process.env.REACT_APP_API_ADDRESS+`/order/${_id}`,{checkedByAdmin:checked})
        setChecked(checked)
    } catch (error) {
        throw error
    }
}
  return(
    <>
    <Dialog
    open={open}
    onClose={()=>setOpen(false)}
    >
      <ButtonGroup>
        <IconButton
        size='large'
        // sx={{border:'1px solid grey'}}
        href={'tel:'+mobile}
        >
          <Call fontSize='inherit'/>
        </IconButton>
        <IconButton
          size='large'
          // sx={{border:'1px solid grey'}}
          href={`sms:${mobile}?&body=${smsText}`}
          >
            <Sms fontSize='inherit'/>
        </IconButton>
      </ButtonGroup>
    </Dialog>
    <ListItem
    key={_id}
    divider
    sx={{
      p:0,
      bgcolor:bgcolor
    }}
    >
      <DoubleCheckButton
      actionName={checked?'取消':'確認'}
      action={()=>{
          handleCheck(!checked)
      }}
      >
      <Checkbox
      id={`checkedByAdmin-${_id}`}
      checked={checked}
      // onClick={
      // }
      />
      </DoubleCheckButton>
      <ListItemButton
      onClick={()=>onOrderClick(_id)}
      sx={{
        pt:0,
        pb:0,
        paddingRight:'6rem',
      }}>
      <Grid 
      container 
      sx={{
        height:'100%'
        }}>
        <Grid 
        container 
        // alignItems='center'
        >
          <Grid item xs>
          <StyledTypo>{orderer+(userVisitingTimes?` (訂${userVisitingTimes}次)`:'')}</StyledTypo>
          </Grid>
          <Grid item xs>
          <StyledTypo>{`備註:(${otherRequest.length+(veganNumber?1:0)})`}</StyledTypo>
          </Grid>
        </Grid>
        <Grid 
        container 
        // spacing={2}
        >
          {/* <Grid item xs>
            <StyledTypo>{shortenText||statusText}</StyledTypo>
          </Grid> */}
          <Grid item xs>
            <StyledTypo>{night+'晚'}</StyledTypo>
          </Grid>
          <Grid item xs>
            <StyledTypo>{`[${roomNumber.join('/')}]`}</StyledTypo>
          </Grid>
          {/* <Grid item xs>
            <StyledTypo>{totalPerson+'人'}</StyledTypo>
          </Grid> */}
        </Grid>
      </Grid>
      </ListItemButton>
      <ListItemSecondaryAction>
              <IconButton
              size='large'
              sx={{border:'1px solid grey'}}
              onClick={()=>{setOpen(true)}}
              >
                <CallIcon fontSize='inherit'/>
              </IconButton>
          </ListItemSecondaryAction>
    </ListItem>
    </>
  )
}
const OrderList = ({orders,onOrderCheck,onOrderClick,smsText,...props}) => {
  // const orderByDate = React.useCallback((orders) => {
  //   return orders.reduce((acc,cur)=>{
  //     const date = new Date(cur.startDate)
  //     const makeNewGroup = (type) => {
  //       return {
  //         date,
  //         item:[cur]
  //       }
  //     }
  //     if(acc.length===0){
  //       return [makeNewGroup()]
  //     }else if(isSameDay(acc[acc.length-1]?.date,date)){
  //       acc[acc.length-1].item.push(cur)
  //       return acc
  //     }else{
  //       return acc.concat([makeNewGroup()])
  //     }
  //   },[])
  // },[])
  const orderByDate = React.useMemo(() => 
  orders.reduce((acc,cur)=>{
      const date = new Date(cur.startDate)
      const makeNewGroup = (type) => {
        return {
          date,
          orders:[cur]
        }
      }
      if(acc.length===0){
        return [makeNewGroup()]
      }else if(isSameDay(acc[acc.length-1]?.date,date)){
        acc[acc.length-1].orders.push(cur)
        return acc
      }else{
        return acc.concat([makeNewGroup()])
      }
    },[])
  ,[orders])
  const StyledTypo = (props)=>(
    <Typography
     sx={{
      // border:'1px solid black',
      p:0.5,
      wordBreak:'keep-all'
     }}
    >
      {props.children}
    </Typography>
  )
  return (
    <Box
    {...props}
    >
      <List 
      sx={{
        
      }}
      subheader={!orders.length?null:<li />}
      >
        {
          !orders.length?
          <Skeleton width='100%' height={50}/>:
          orderByDate.map(({date,orders},index)=>{
            return(
              <li key={index}>
                <ul>
                  <ListSubheader
                  sx={{
                    color:'white',
                    bgcolor:'primary.main'
                  }}
                  >
                  {
                  `${format(date,'yyyy/MM/dd')}`
                  }
                  </ListSubheader>
                  {
                    orders.map((order,index)=>{
                      return(
                        <CustomListItem
                        key={order._id}
                        order={order}
                        onOrderClick={onOrderClick}
                        onOrderCheck={onOrderCheck}
                        smsText={smsText}
                        />
                      )
                    })
                  }
                </ul>
              </li>
            )
          })
          
        }
      </List>
    </Box>
  )
}

export default OrderList