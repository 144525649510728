import {RouterProvider,createBrowserRouter,redirect,Navigate } from 'react-router-dom';
import Orders from './page/orders/Orders';
import { useContext } from 'react';
import { UserContext} from './context/UserContext';
import Auth from './page/auth/Auth';
import Setting from './page/setting/Setting';
import ButtonAppBar from './component/buttonAppBar/ButtonAppBar';
import Calendar from './page/calendar/Calendar';
import Discounts from './page/discounts/Discounts';
import Report from './page/report/Report';
import Order from './page/order/Order';
import PadView from './component/padView/PadView';
import Room from './page/room/Room';
import MealArrange from './page/mealArrange/MealArrange';
import Housekeeping from './page/housekeeping/Housekeeping';
import Staff from './page/staff/Staff';
import StaffView from './component/staffView/StaffView';
import BirthdayCake from './component/birthdayCake/BirthdayCake';
import Clockin from './page/clockin/Clockin';
import ClockinQrCode from './page/clockinQrCode/ClockinQrCode';

function App() {
  const {user} =  useContext(UserContext)
  const {isAdmin,isStaff,userId} = user||{}
  const loader_main = ()=>{
    if(isAdmin||isStaff){
      return null
    }
    return redirect("/login");
  }
  const loader_auth = ()=>{
    if(isAdmin||isStaff){
      return redirect('/');
    }else{
      return null;
    }
  }
  const router = createBrowserRouter([
    {
      path:'/',
      loader:loader_main,
      element:<ButtonAppBar/>,
      children:[
        {
          path:'/',
          element:
          // <BirthdayCake/>
            <Navigate 
            to={isAdmin?'/order':`/staff/${userId}`}
            replace={true}
            />
        },
        {
          path:'order',
          element:<Orders/>,
          children:[
            {
              path:':_id',
              element:<Order/>
            }
          ]
        },
        {
          path:'calendar',
          element:<Calendar/>
        },
        {
          path:'room',
          element:<Room/>
        },
        {
          path:'setting',
          element:<Setting/>
        },
        {
          path:'discount',
          element:<Discounts/>
        },
        {
          path:'report',
          element:<Report/>
        },
        {
          path:'meal_arrange',
          element:<MealArrange/>
        },
        {
          path:'housekeeping',
          element:<Housekeeping/>
        },
        {
          path:'staff',
          element:<Staff/>
        },
        {
          path:'staff/:id',
          element:<StaffView/>
        },
        {
          path:'clockin',
          element:<ClockinQrCode/>
        },
        {
          path:'clockin/hash/?',
          element:<Clockin/>
        }
      ]
    },
    {
      path:'/pad',
      element:<PadView/>
    },
    {
      path:'/login',
      element:<Auth/>,
      loader:loader_auth,
    },
    {
      path:'/error',
      element:'您非工作人員',

    }
  ])
  return (
      <RouterProvider router={router}/>
  );
}

export default App;
