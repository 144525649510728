import { Button, List, ListItem, ListSubheader } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { addMonths, format, subDays, subMonths } from 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import React, { useMemo } from 'react'
import { zhCN } from 'date-fns/locale'

const Report = () => {
    const [date,setDate] = React.useState(
        getFirstAndLast(subMonths(new Date(),1)),
     )
     //eslint-disable-next-line react-hooks/exhaustive-deps
    const link = useMemo(()=>getDownloadLink(),[date])
    function getFirstAndLast (theDate) {
        const startDate = new Date(format(theDate,'yyyy/MM'))
        const endDate = subDays(addMonths(startDate,1),1)
        return {startDate,endDate}
    }
    function getDownloadLink () {
        return process.env.REACT_APP_API_ADDRESS+
                '/setting/report/?'+
                'startDate='+date.startDate.toISOString()+
                '&endDate='+date.endDate.toISOString()
    }
  return (
    <div>
        <List
        subheader={<ListSubheader children='下載訂單'/>}
        >
            <ListItem>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhCN}>
                    <DatePicker
                    value={date.startDate}
                    views={['month','year']}
                    format='yyyy/MM'
                    onChange={(date)=>setDate(getFirstAndLast(date))}
                    />
                </LocalizationProvider>
            </ListItem>
            <ListItem>
                <Button
                variant='contained'
                href={link}
                >
                    下載CSV
                </Button>
            </ListItem>
            {
            // reports.map(({year,month},index)=>
            // <ListItem
            // divider
            // key={index}
            // secondaryAction={
            //     <Button
            //     variant='contained'
            //     children='下載csv'
            //     />
            // }
            // >
            //     <ListItemText
            //     primary={`${year}/${month}`}
            //     />
            // </ListItem>
            // )
            }
        </List>
    </div>
  )
}

export default Report