import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const AddItemButton = ({onClick,text,sx}) => {
  return (
    <Box
        component={'div'}
        sx={{
            position:'relative',
            boxSizing:'border-box',
            pl:2,
            pr:2,
            ...sx
        }}
        >
        <Button
        variant='outlined'
        fullWidth
        onClick={()=>onClick()}
        startIcon={<Add/>}
        >
            {text}
        </Button>
        </Box>
  )
}

export default AddItemButton