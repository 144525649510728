import axios from 'axios'
import * as React from 'react'
export const useFetch = (url,params) => {
    const [data,setData] = React.useState([])
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState(null)
    const fetch = async () => {
        let data = []
        setLoading(true)
        try {
            const res = await axios.get(
                url,
                {
                    params,
                    'withCredentials':true
                })
            data = res.data
        } catch (error) {
            setError(error)
        }
        setData(data)
        setLoading(false)
    }
    React.useEffect(()=>{
        fetch()
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[url,params])
    return { 
            data,
            loading,
            error,
            refetch:fetch
            }
}