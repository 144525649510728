import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'

const StyledSelect = ({label,menu,value,multiple,onChange}) => {
  return (
    <FormControl
        // variant='standard'
        size='small'
        sx={{
            minWidth:120,
            m:1
        }}
        >
            <InputLabel 
            id='select-time-label'>
                {label}
            </InputLabel>
            <Select
            labelId='select-time-label'
            id='select-time'
            label={label}
            value={value||(multiple?[]:'')}
            onChange={(e)=>{
                const value = e.target.value
                onChange(multiple&&value?.includes('')?[]:value)
            }}
            multiple={multiple}
            MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                sx:{
                    height:'360px'
                }
              }}
            >
                {
                multiple&&
                <MenuItem
                value={''}
                children='取消選擇'
                />
                }
                {menu.map((v)=>
                <MenuItem 
                key={v}
                value={v}
                >{v}</MenuItem>
                )
                }
            </Select>
        </FormControl>
  )
}

export default StyledSelect