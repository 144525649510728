import { differenceInHours, differenceInMinutes } from "date-fns"

export const getOrderStatus = (order) => {
    if(Object.keys(order).length<1){
        return {}
    }
    const {
        orderType,
        'transferDepositBefore':TDB,
        'acctualDepositAmount':ADA,
        'lastFiveDigitsOfBankAccountFromSender':LFD,
        'isDepositApproved':IDA
    } = order
    const timer = function(){
        const difference = (
            new Date(TDB).getTime()
            -new Date().getTime()
            )/(60*60*1000)
        return difference.toFixed(1)
    }()
    // if(orderType==='booking'){
    //     return 'BOOKING訂單'
    // }
    if(orderType==='availible'){
        if(IDA){
            return {
                status:'approved',
                text:`預定成功`
            }
        }else if(ADA>0||LFD?.length>0){
            return {
                status:'confirming',
                text:`等待確認`
            }
        }else if(timer>0){
            return {
                status:'pending',
                text:`${differenceInHours(new Date(TDB),new Date())}小時${differenceInMinutes(new Date(TDB),new Date())%60}分鐘 後截止付訂`,
                shortenText:'尚未付訂'
            }
        }else{
            return {
                status:'outdated',
                text:'過期訂單'
            }
        }
    }
    if(orderType==='waiting'){
        return {
            status:'waiting',
            text:`候補中...`
        }
    }
    if(orderType==='completed'){
        return {
            status:'completed',
            text:`已完成`
        }
    }
}