import { Button, ButtonGroup, Checkbox, ImageList, ImageListItem, ImageListItemBar, Paper } from '@mui/material'
import axios from 'axios'
import React from 'react'
import DoubleCheckButton from '../doubleCheckButton/DoubleCheckButton'

const CustomImageList = ({photo,refetch,onUpdate,onDelete}) => {
    // axios.defaults.withCredentials = true
    const imageWidth = 200
    const [sortedPhoto,setSortedPhoto] = React.useState(photo.concat())
    const [selection,setSelection] = React.useState([])
    // const [newPhoto,setNewPhoto]=  React.useState()
    const [isUploading,setIsUploading] = React.useState(false)
    const [isDeleting,setIsDeleting] = React.useState(false)
    const [col,setCol] = React.useState(Math.floor((window.innerWidth/imageWidth)))
    const handleUpload = async (e) => {
        setIsUploading(true)
        let formData = new FormData()
        formData.append('image',e.target.files[0])
        try {
            const res = await axios.post(
                `https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_IMGBB_KEY}`,
                formData,
                {
                    headers:{
                        'Content-Type':'multipart/form-data'
                    }
                }
            )
            const photoData = res.data.data
            await onUpdate(photo.concat([photoData]))
            setIsUploading(false)
        } catch (error) {
            console.log(error)
        }
    }
    const handleDelete = async () => {
        try {
            setIsDeleting(true)
            const ids = sortedPhoto.filter((v,i)=>selection.includes(i)).map(({id})=>id)
            // await axios.delete(process.env.REACT_APP_API_ADDRESS+'/setting/photo/?',
            // {
            //     withCredentials:true,
            //     params:{id:ids}
            // })
            await onDelete(ids)
            setIsDeleting(false)
            refetch()
        } catch (error) {
            console.log(error)
        }
    }
    const handleSelect = (e) => {
        const index = Number(e.target.id.split('-')[1])
        if(selection.includes(index)){
            setSelection((prev)=>prev.concat().filter((i)=>i!==index))
        }else{
            setSelection((prev)=>prev.concat([index]))
        }
    }
    const handleSwap = (direction) => {
        if(selection.length===1){
            const newPrev = sortedPhoto.concat()
            const nextIndex = (
                                sortedPhoto.length+
                                ((selection[0]) + direction)
                                ) % sortedPhoto.length
            newPrev[nextIndex] = newPrev.splice(selection[0], 1, newPrev[nextIndex])[0]
            setSortedPhoto(newPrev)
            setSelection([nextIndex])
        }
    }
    React.useEffect(()=>{
        const handleCol = () => {
        setCol(()=>Math.floor((window.innerWidth/imageWidth)))
        }
        window.addEventListener('resize',handleCol)
        return(
            ()=>window.removeEventListener('resize',handleCol)
        )
    },[])
    React.useEffect(()=>{
        if(selection.length===1){
            const element = document.getElementById(`photo-${selection[0]}`)
            if(element){
                element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
            }
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[sortedPhoto])
    React.useEffect(()=>{
        setSortedPhoto(photo.concat())
    },[photo])
    const hasSwaped = React.useMemo(()=>photo.some(({id},index)=>!(id===sortedPhoto[index]?.id))
    //eslint-disable-next-line react-hooks/exhaustive-deps
    ,[sortedPhoto])
    return(
    <Paper
    sx={{
        p:2,
        position:'relative'
    }}
    >
    <Paper
    sx={{
        display:'flex',
        flexDirection:'column',
        gap:1,
        position:'sticky',
        top:10,
        zIndex:1
    }}
    >
    <ButtonGroup
    fullWidth
    >
        <Button
        disabled={selection.length!==1}
        children='前移'
        onClick={(e)=>{
            e.stopPropagation()
            handleSwap(-1)
        }}
        />
        <Button
        disabled={selection.length!==1}
        children='後移'
        onClick={(e)=>{
            e.stopPropagation()
            handleSwap(1)
        }}
        />
        <DoubleCheckButton
        actionName='確定刪除?'
        action={()=>handleDelete()}
        children={
        <Button
        disabled={
            selection.length===0||
            hasSwaped
        }
        children={isDeleting?'正在刪除...':'刪除'}
        />}
        sx={{
            border:'none',
            ':hover':{border:'none'}
        }}
        />
        {/* </Button> */}
        <Button
        // component='label'
        disabled={hasSwaped}
        onClick={()=>{
            document.getElementById('imagePicker').click()
        }}
        children={<>
            {
            isUploading?
            '...':
            '新增'
            }
            <input
            hidden
            id='imagePicker'
            type='file'
            accept='.jpg,.png'
            onChange={(e)=>handleUpload(e)}
            />
        </>
        }
        />
    </ButtonGroup>
    {hasSwaped&&
        <ButtonGroup>
        <Button
        variant='contained'
        color='warning'
        children='確定?'
        onClick={()=>onUpdate(sortedPhoto)}
        />
        <Button
        variant='contained'
        color='primary'
        children='取消'
        onClick={()=>setSortedPhoto(photo)}
        />
        </ButtonGroup>
    }
    </Paper>
    <ImageList
    sx={{
        position:'relative',
        zIndex:0,
        mt:2
    }}
    cols={col}
    gap={1}
    >
            {
                sortedPhoto.map((i,index)=>{
                const checked = selection.includes(index)
                return(
                <ImageListItem
                key={index}
                sx={{
                    // maxWidth:imageWidth
                }}
                >
                    <img
                    src={i.thumb.url}
                    alt={i.title}
                    loading='lazy'
                    style={{
                    }}
                    />
                    <ImageListItemBar
                    sx={{
                        background:
                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                    }}
                    position='top'
                    actionIcon={
                        <Checkbox
                        sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: 'white'
                            },
                          }}
                        id={`photo-${index}`}
                        checked={checked}
                        onChange={handleSelect}
                        />
                    }
                    actionPosition="left"
                    />
                </ImageListItem>
                )})
            }
    </ImageList>
    </Paper>
    )
}

export default CustomImageList