import { Button, ClickAwayListener, Collapse, InputAdornment, Paper, TextField } from '@mui/material'
import { CalendarIcon, DateCalendar, LocalizationProvider, PickersDay } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { format, isAfter, isBefore, isDate, isSameDay, isValid} from 'date-fns'
import React from 'react'

const DateRangePicker = ({input,output,minDate,maxDate,sx}) => {
    const [extend,setExtend] = React.useState(false)
    const [text, setText] = React.useState(dateToText(input?.startDate,input?.endDate))
    const [date,setDate] = React.useState(
    input?
        {
            startDate:new Date(new Date(input.startDate).toDateString()),
            endDate:new Date(new Date(input.endDate).toDateString())
        }:
        {
            startDate:null,
            endDate:null
        }
    )
    const collapse = date?.endDate&&['startDate','endDate'].some((key)=>
    !input||
    !isSameDay(input[key],date[key])
    )
    const isInBetween = (day, date) => {
        if(!date){return false}
        return date.endDate?
                !(isBefore(day,date.startDate)||
                isAfter(day,date.endDate)):
                isSameDay(day,date?.startDate)
    }
    function dateToText (startDate,endDate) {
        if(!startDate&&!endDate){
            return '請選擇日期'
        }
        if(isSameDay(startDate,endDate)){
            return format(startDate,'yyyy/MM/dd')
        }else{
            return [startDate,endDate].map((date)=>isDate(date)?format(date,'yyyy/MM/dd'):'').join(' - ')
        }
    }
    const handleCalendar = (value) =>{
        const formatedValue = new Date(value.toDateString())
        let startDate,endDate
        ([startDate,endDate] = (
                                !date.startDate||
                                (date.startDate&&date.endDate)
                                )?
                                    [formatedValue,null]:
                                    [date.startDate,formatedValue].sort((a,b)=>isBefore(a,b)?-1:1))
        setDate({
            startDate,
            endDate
        })
        setText(dateToText(startDate,endDate))
    }
    const handleText = (value) => {
        const regex = /20[0-9]{2}\/[01][0-9]\/[0-3][0-9](?:.*?)/g
                const found = value.match(regex)
                const dates = found?.map((date)=>new Date(date))||[]
                const valid = !dates.some((date)=>{
                                return !isValid(date)
                                })&&
                                (
                                (
                                dates.length===1
                                )||
                                (
                                dates.length===2&&
                                isBefore(dates[0],dates[1])
                                )
                                )

                if(valid){
                  setDate(
                    {
                        startDate:dates[0],
                        endDate:dates?.[1]||dates[0]
                    }
                  )
                }else if(date!==input){
                    setDate(input)
                }
    }
    function Day(props) {
      const { 
          day,
            ...other 
        } = props;
      return (
        <PickersDay
          {...other}
          day={day}
          sx={{
            borderRadius:0
          }}
          disableMargin
          today={false}
          selected={isInBetween(day,date)}
          disabled={
            (minDate&&isBefore(day,minDate))||
            (maxDate&&isAfter(day,maxDate))
        }
        onClick={()=>handleCalendar(day)}
        />
      );
    }
    React.useEffect(()=>{
        setDate(
            input?
            {
                startDate:new Date(new Date(input.startDate).toDateString()),
                endDate:new Date(new Date(input.endDate).toDateString())
            }:
            {
                startDate:null,
                endDate:null
            }
        )
        setText(dateToText(input?.startDate,input?.endDate))
    },[input])
    return(
        <ClickAwayListener
            onClickAway={()=>{
            setExtend(false)
            setDate(input||{startDate:null,endDate:null})
            setText(dateToText(input?.startDate,input?.endDate))
            }}
        >
        <Paper
        sx={{
            minWidth:'250px',
            ...sx
        }}
        >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TextField
            InputProps={{
                endAdornment: (
                    <InputAdornment 
                    position="end"
                    sx={{
                    }}
                    >
                    <CalendarIcon/>
                    </InputAdornment>
                )
            }}
            sx={{
            // minWidth:'250px',
            width:1,
            '*':{
            cursor: 'pointer'
            }
            }}
            variant='outlined'
            // fullWidth
            value={text}
            onClick={()=>setExtend(true)}
            onChange={(e)=>{
                setText(e.target.value)
                handleText(e.target.value)
            }}
        />
        {
            <Collapse
            in={extend}
            >
            <Paper
            sx={{
                width:1,
                overflowX:'scroll',
                '::-webkit-scrollbar':{
                    backgroundColor:'transparent',
                    display: 'block',
                    height: '20px',
                },
                '::-webkit-scrollbar-track':{
                    // backgroundColor:'green',
                    // border:'solid 3px transparent'
                },
                '::-webkit-scrollbar-thumb':{
                    bgcolor:'primary.main',
                    backgroundClip:'padding-box',
                    border:'solid 5px transparent',
                    borderRadius:'10px',
                  }
                // p:1
            }}
            >
            {extend&&
            <DateCalendar
                reduceAnimations
                // onChange={(newValue) => handleCalendar(newValue)}
                showDaysOutsideCurrentMonth
                slots={{ day: Day }}
                />
            }
            </Paper>
            <Collapse
            in={collapse}
            >
                <Button
                fullWidth
                variant='contained'
                children='確定'
                onClick={()=>{
                output(date)
                setExtend(false)
                }}
                />
            </Collapse>
            </Collapse> 
        }
        </LocalizationProvider>
        </Paper>
        </ClickAwayListener>
    )
}

export default DateRangePicker