import axios from "axios"
import { isBefore } from "date-fns"
import { createContext, useEffect, useReducer } from "react"

const INITIAL_STATE = {
    user:getUser(),
    loading:false,
    error:null
}
export const UserContext = createContext(INITIAL_STATE)
const UserReducer = (state,action) => {
    switch(action.type){
        case 'LOGIN_START':
            return {
                user:null,
                loading:true,
                error:null
            }
        case 'LOGIN_SUCCESS':
            return {
                user:action.payload,
                loading:false,
                error:null
            }
        case 'LOGIN_FAILURE':
            return {
                user:null,
                loading:false,
                error:action.payload
            }
        case 'LOGOUT':
            return {
                user:{},
                loading:false,
                error:null
            }
        default: return state
    }
}
export const UserContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(UserReducer,INITIAL_STATE)
    useEffect(()=>{
        const setData = async () => {
            try {
                const hasUser = Object.keys(state.user).length>0
                if(!hasUser){
                    await axios.post(process.env.REACT_APP_API_ADDRESS+'/auth/logout')
                    localStorage.removeItem('user')
                }else{
                    localStorage.setItem('user',JSON.stringify(state.user))
                }
            } catch (error) {
                console.log(error)
            }
        }
        setData()
    },[state])
    return(
        <UserContext.Provider
        value={{
            ...state,
            dispatch
        }}
        >
            {children}
        </UserContext.Provider>
    )
}

function getUser () {
    const user = JSON.parse(localStorage.getItem('user'))
    const isValid = user&&
                    isBefore(
                            new Date(),
                            new Date(
                                user.expires
                                )
                    )
    return isValid?user:{}
}