import React from 'react'
import { useFetch } from '../../hook/useFetch'
import { Button, ButtonGroup, IconButton, InputAdornment, List, ListItem, ListItemText, ListSubheader, Paper, TextField } from '@mui/material'
import { addDays, format } from 'date-fns'
import axios from 'axios'
import AddItemButton from '../../component/addItemButton/AddItemButton'
import { Box } from '@mui/system'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Add, Cancel } from '@mui/icons-material'
import ToTopButton from '../../component/toTopButton/ToTopButton'
import DoubleCheckButton from '../../component/doubleCheckButton/DoubleCheckButton'

const Discounts = () => {
    axios.defaults.withCredentials = true
    const url = process.env.REACT_APP_API_ADDRESS+'/setting/discount'
    const {
        data,
        refetch
    } = useFetch(url)
    const blankDiscount = {
        title:'新優惠',
        discountType:"byRate",
        discountAmount:0.9,
        expiryDate:addDays(new Date(new Date().toDateString()),180),
        userIds:[]
        }
    const [newDiscount,setNewDiscount] = React.useState(null)
    const [isNew,setIsNew] = React.useState(false)  
    const [search,setSearch] = React.useState(null)
    const [users,setUsers] = React.useState([])
    const createDiscount = async (req) => {
        try {
            await axios.post(url,req)
            refetch()
            closeCreateDiscount()
        } catch (error) {
            console.log(error)
        }
    }
    const closeCreateDiscount = () => {
        setNewDiscount(null)
        setSearch(null)
        setUsers([])
    }
    const deleteDiscount = async (req) => {
        try {
            await axios.delete(url,{data:req})
            refetch()
        } catch (error) {
            console.log(error)
        }
    }
    const getUser = async (req) => {
        try {
            const result = await axios.get(process.env.REACT_APP_API_ADDRESS+'/user/?',{params:req})
            setSearch((prev)=>({text:req.mobile,target:result.data[0]}))
        } catch (error) {
            console.log(error)
        }
    }
    const isByPrice = newDiscount?.discountType === 'byPrice'
  return (
    <div
    className='Discount'
    style={{
        position:'relative',
        zIndex:0,
        marginBottom:52
    }}
    >
        <List
        subheader={<ListSubheader children='建立新的優惠'/>}
        >
        {
            newDiscount&&
            <>
            <ListItem>
            <ButtonGroup>
                <Button
                variant={isNew?'contained':'outlined'}
                children='新增'
                onClick={()=>{
                    setNewDiscount(blankDiscount)
                    setIsNew(true)
                }}
                />
                <Button
                variant={!isNew?'contained':'outlined'}
                children='補充'
                onClick={()=>{
                    setNewDiscount(
                        {...blankDiscount,
                        discountType:undefined,
                        discountAmount:undefined,
                        expiryDate:undefined}
                        )
                    setIsNew(false)
                    }}
                />
            </ButtonGroup>
            </ListItem>
            <ListItem>
                <TextField
                size='small'
                label='優惠名稱'
                value={newDiscount?.title}
                onChange={(e)=>setNewDiscount((prev)=>({...prev,title:e.target.value}))}
                />
            </ListItem>
            {
            isNew&&
            <>
            <ListItem
            sx={{
                gap:2
            }}
            >
                <ButtonGroup
                sx={{
                    '*':{wordBreak:'keep-all'}
                }}
                >
                    <Button
                    variant={!isByPrice?'contained':'outlined'}
                    children='%'
                    onClick={()=>setNewDiscount((prev)=>({...prev,discountType:'byRate',discountAmount:0.95}))}
                    />
                    <Button
                    variant={isByPrice?'contained':'outlined'}
                    children='$'
                    onClick={()=>setNewDiscount((prev)=>({...prev,discountType:'byPrice',discountAmount:100}))}
                    />
                </ButtonGroup>
                <TextField
                hiddenLabel
                type='number'
                size='small'
                InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                        {
                        isByPrice?
                        '元':
                        '%'
                        }
                    </InputAdornment>,
                  }}
                value={
                    String(
                        isByPrice?
                        newDiscount?.discountAmount:
                        newDiscount?.discountAmount*100
                        ).replace(/^0+/,'')
                }
                onChange={(e)=>{
                    const value = e.target.value
                    setNewDiscount((prev)=>({
                        ...prev,
                        discountAmount:isByPrice?value:value/100
                    }))
                }}
                />
            </ListItem>
            <ListItem>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                    label='截止日期'
                    disablePast
                    value={newDiscount?.expiryDate}
                    format='yyyy/MM/dd'
                    onChange={(date)=>setNewDiscount((prev)=>({...prev,expiryDate:date}))}
                    />
                </LocalizationProvider>
            </ListItem>
            </>
            }
            <ListItem>
                <Paper>
                <List
                sx={{width:1}}
                >
                    <ListItem>
                        <TextField
                        size='small'
                        label='新增受贈者'
                        placeholder='填入會員手機'
                        color={ search?
                                search?.target?'success':'error':
                                undefined
                                }
                        onChange={(e)=>{
                            const value = e.target.value
                            if(value.match(/^09(\d){8}$/)){
                                getUser({mobile:value})
                            }else if(value.length>0){
                                setSearch((prev)=>({text:value}))
                            }else{
                                setSearch(null)
                            }
                        }}
                        />
                    </ListItem>
                    {
                            search?.target&&
                            <ListItem>
                            <Button
                            variant='contained'
                            startIcon={<Add/>}
                            children={search.target.username}
                            onClick={()=>{
                                if(users.some(({_id})=>_id===search.target._id)){
                                    return
                                }
                                setUsers((prev)=>prev.concat([search.target]))
                            }}
                            />
                            </ListItem>
                    }
                    {
                        users.map((value,index)=>{
                            return(
                                <ListItem
                                key={index}
                                secondaryAction={
                                    <IconButton
                                    color='error'
                                    children={<Cancel/>}
                                    onClick={()=>setUsers((prev)=>{
                                        const update = prev.concat()
                                        update.splice(index,1)
                                        return update
                                    })}
                                    />
                                }
                                >
                                <ListItemText
                                primary={value.username}
                                secondary={value.mobile}
                                />
                                </ListItem>
                            )
                        })
                    }
                </List>
                </Paper>
            </ListItem>
            </>
        }
        {newDiscount?
            <ListItem
            sx={{
                gap:2
            }}
            >
                <Button
                variant='contained'
                color='primary'
                children='建立'
                onClick={()=>createDiscount({...newDiscount,userIds:users.map(({_id})=>_id)})}
                />
                <Button
                variant='contained'
                color='error'
                children='取消'
                onClick={()=>closeCreateDiscount()}
                />
            </ListItem>:
            <AddItemButton
            text={'開始建立'}
            onClick={()=>{
                setNewDiscount(blankDiscount)
                setIsNew(true)
            }}
            />
        }
        </List>
        <List
        subheader={<ListSubheader children='已建立的優惠'/>}
        >
            {
                data.map((item,index)=>{
                const {amount,unit} = item.discountType==='byPrice'?
                                        {
                                            amount:item.discountAmount,
                                            unit:'元'
                                        }:
                                        {
                                            amount:item.discountAmount*10,
                                            unit:'折'
                                        }
                return(
                <ListItem
                key={index}
                divider
                secondaryAction={
                    <Box
                    sx={{display:'flex'}}
                    >
                    <IconButton
                    children={<Add/>}
                    onClick={()=>{
                        setNewDiscount(
                            {
                                title:item.title
                            })
                        setIsNew(false)
                        window.scrollTo({top:0,behavior:'smooth'})
                    }}
                    />
                    <DoubleCheckButton
                    icon={<Cancel/>}
                    actionName='確定'
                    action={()=>deleteDiscount({discountIds:item.discountIds.map(({discountId})=>discountId)})}
                    />
                    </Box>
                }
                >
                    <ListItemText
                    primary={`「${item.title}」 ： ${amount} ${unit}`}
                    secondary={`${format(new Date(item.expiryDate),'yyyy/MM/dd')}截止，${item.usedNumber}/${item.issuedNumber}（已使用／總量）`}
                    />
                </ListItem>
                )
                })
            }
        </List>
        <ToTopButton
        triggerHeight={30}
        />
    </div>
  )
}

export default Discounts