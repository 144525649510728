import { ArrowUpwardTwoTone } from '@mui/icons-material'
import { Fab } from '@mui/material'
import React from 'react'

const ToTopButton = ({triggerHeight}) => {
    const [show,setShow] = React.useState(false)
    const [target,setTarget] = React.useState(null)
    React.useEffect(()=>{
      const element = document.getElementById('button-app-bar-container')
      if(element){
        setTarget(element)
        element.addEventListener('scroll',handleUpBtn)
        return( ()=>
        element.removeEventListener('scroll',handleUpBtn)
        )
        function handleUpBtn () {
          if(element.scrollTop>triggerHeight){
            setShow(true)
          }else if(()=>show)(
            setShow(false)
          )
        }}
      //eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
  return (
    <div
    id='to-top-button-anchor-point'
    >
    {show&&
    <Fab
        sx={{
          position:'fixed',
          right:16,
          bottom:16
        }}
        onClick={()=>{
          target.scrollTo({top:0,behavior:'smooth'})
        }}
        >
          <ArrowUpwardTwoTone/>
    </Fab>
    }
    </div>
  )
}

export default ToTopButton