import React from 'react'
import { useFetch } from '../../hook/useFetch'
import './orders.scss'
import OrderList from '../../component/orderList/OrderList.jsx'
import { Button, ButtonGroup, Dialog, FormControl, FormLabel, Paper, Select, TextField } from '@mui/material'
import ToTopButton from '../../component/toTopButton/ToTopButton'
import AddItemButton from '../../component/addItemButton/AddItemButton'
import { addDays, subDays } from 'date-fns'
import DateRangePicker from '../../component/dateRangePicker/DateRangePicker'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Box } from '@mui/system'
import StyledSelect from '../../component/styledSelect/StyledSelect.jsx'
const CreateOrderView = ({onCreate}) => {
  const roomNumbers = ['101','102','201','202','203','204','205']
  const veganTypes = [
    {alias:'蛋奶素',value:'ovo-lacto'},
    {alias:'全素',value:'vegan'},
    {alias:'無',value:'none'}
  ]
  const today = new Date(new Date().toLocaleDateString('en-US',{timeZone:'Asia/Taipei'}))
  const defaultReq = {
    "noConfirmation":true,
    "ignoreClosed":true,
    "ignoreDisplayDateRange":true,
    "isDraft":true,
    "isTesting":false,
    "isDepositApproved": true,
    "actualDepositAmount":0,
    startDate:today,
    endDate:addDays(today,1),
    roomNumber:[],
    "orderType":"availible",
    "addPersonRoomNumber":[],
    "orderer":'',
    "mobile":'',
    "address":'',
    'email':'',
    "veganNumber":0,
    "veganType":'none',
    "otherRequest":[]
}
  const [req,setReq] = React.useState(defaultReq)
  function handleSetRequest(input) {
    setReq((prev)=>({...prev,...input}))
  }
  return(
      <div
        style={{
          display:'flex',
          flexDirection:'column',
          padding:'12px'
        }}
        >
        <Button
        variant='contained'
        onClick={async()=>{
          try {
            await onCreate(req)
            // setReq(defaultReq)
          } catch (error) {
            console.log(error)
          }
        }}
        >送出</Button>
        <Paper
        sx={{
          m:1
        }}
        >
        <FormLabel>入住日-退房日</FormLabel>
        <DateRangePicker
        input={{
          startDate:req.startDate,
          endDate:req.endDate
        }}
        output={(date)=>{
          handleSetRequest(date)
        }}
        />
        </Paper>
        <StyledSelect
        label='選擇房型'
        menu={roomNumbers}
        value={req.roomNumber}
        multiple={true}
        onChange={(value)=>handleSetRequest({roomNumber:value.sort((a,b)=>a-b)})}
        />
       
        <TextField
            sx={{
              m:1
            }}
            size='small'
            label='姓名'
            type='text'
            value={req.orderer}
            onChange={(e)=>
                handleSetRequest({orderer:e.target.value})
            }
            />
        <TextField
            sx={{
              m:1
            }}
            size='small'
            label='手機'
            type='text'
            value={req.mobile}
            onChange={(e)=>
                handleSetRequest({mobile:e.target.value})
            }
            />
        <TextField
            sx={{
              m:1
            }}
            size='small'
            label='地址'
            type='text'
            value={req.address}
            onChange={(e)=>
                handleSetRequest({address:e.target.value})
            }
            />
         <StyledSelect
        label='選擇素食'
        menu={veganTypes.map(i=>i.alias)}
        value={veganTypes.find(i=>i.value===req.veganType).alias}
        onChange={(value)=>handleSetRequest({veganType:veganTypes.find(i=>i.alias===value).value})}
        />
        <TextField
            sx={{
              m:1
            }}
            size='small'
            label='素食人數'
            type='number'
            value={req.veganNumber.toString()}
            onChange={(e)=>
                handleSetRequest({veganNumber:Number(e.target.value)})
            }
            />
        <TextField
            sx={{
              m:1
            }}
            size='small'
            label='已付訂金'
            type='number'
            value={req.actualDepositAmount.toString()}
            onChange={(e)=>
                handleSetRequest({actualDepositAmount:Number(e.target.value)})
            }
            />
        <TextField
            sx={{
              m:1
            }}
            size='small'
            label='需求'
            type='text'
            multiline
            value={req?.otherRequest?.[0]?.request||''}
            onChange={(e)=>
                handleSetRequest({otherRequest:[{request:e.target.value}]})
            }
            />
        </div>
  )
}
const Orders = () => {
    const today = new Date(new Date().toDateString())
    const lastMonth = {
      startDate:subDays(today,30),
      endDate:today
    } 
    const nextMonth = {
      startDate:today,
      endDate:addDays(today,30)
    }
    const nextYear = {
      startDate:today,
      endDate:addDays(today,365)
    }
    const [query,setQuery] = React.useState({
      type:'orders',
      ...nextMonth
    })
    const [openCreateOrder,setOpenCreateOrder] = React.useState(false)
    const {_id} = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const {
      data:orders,
      refetch:orderRefetch
    } = useFetch(process.env.REACT_APP_API_ADDRESS+'/setting/orderlist/',query)
    const {
      data,
    } = useFetch(process.env.REACT_APP_API_ADDRESS+'/setting/?query[]=smsText')
    React.useEffect(()=>{
      orderRefetch()
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[location])
    async function handleCreateOrder (req) {
      axios.defaults.withCredentials = true
      try {
        await axios.post(process.env.REACT_APP_API_ADDRESS+'/order/new',req)
        orderRefetch()
        setOpenCreateOrder(false)
      } catch (error) {
        throw error
      }
    }
  return (
    <div 
    className='orders' 
    style={{
      position:'relative',
      minWidth:'360px',
      maxWidth:'640px',
    }}
    >
        <Outlet/>
        <Dialog
        open={openCreateOrder}
        onClose={()=>setOpenCreateOrder(false)}
        >
        <CreateOrderView
        onCreate={handleCreateOrder}
        />
        </Dialog>
        <div
        hidden={_id?true:false}
        >
        <Paper
        sx={{
          p:2,
          display:'flex',
          flexDirection:'column',
          gap:2
        }}
        >
        <ButtonGroup
        fullWidth
        >
          {
            [
              {
                type:'orders',
                label:'進行'
              },
              {
                type:'pending',
                label:'核准'
              },
              {
                type:'unreplied',
                label:'回覆'
              },
              {
                type:'completed',
                label:'完成'
              }
            ].map(({type,label})=>
            <Button
            key={type}
            variant={query.type===type?'contained':'outlined'}
            id={type}
            sx={{
              wordBreak:'keep-all'
            }}
            children={label}
            onClick={()=>{
              let timespan
              if(type==='orders'){
                timespan = nextMonth
              }else if(type==='completed'){
                timespan = lastMonth
              }else if(['pending','unreplied'].includes(type)){
                timespan = nextYear
              } 
              setQuery((prev)=>({type,...(timespan)}))
            }}
            />
            )
          }
          <Button
          onClick={()=>setOpenCreateOrder(true)}
          >
            +
          </Button>
        </ButtonGroup>
        <DateRangePicker
        input={{
          startDate:query.startDate,
          endDate:query.endDate
        }}
        output={(date)=>{
          setQuery((prev)=>({...prev,...date}))
        }}
        />
        </Paper>
        <OrderList
        smsText={data?.smsText}
        orders={orders}
        style={{
          position:'absolute',
          zIndex:0,
          width:'100%',
          paddingBottom:100
        }}
        onOrderClick={(_id)=>{
            // navigate(`/order/${query.type==='completed'?'completed/':''}${_id}`,{state:{mode:query.type==='unreplied'?1:0}})
            navigate(`/order/${_id}`,
            {state:{
              hasCompleted:query.type==='completed'?true:false,
              mode:query.type==='unreplied'?1:0
            }})
        }}
        />
        {!_id&&
        <ToTopButton
        triggerHeight={30}
        />
        }
        </div>
    </div>
  )
}

export default Orders