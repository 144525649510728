import { Button, Chip, Dialog, FormControl, FormControlLabel, IconButton, InputLabel, List, ListItem, MenuItem, Paper, Select, Switch, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import './housekeeping.scss'
import { useFetch } from '../../hook/useFetch'
import { format, isBefore } from 'date-fns'
import { zhTW } from 'date-fns/locale'
import { useNavigate } from 'react-router-dom'
import { Add } from '@mui/icons-material'
import { blue } from '@mui/material/colors'
import axios from 'axios'
import { UserContext } from '../../context/UserContext'

const CustomSelect = ({label,value,onChange,renderMenu}) => {
    return(
        <FormControl
        fullWidth
        >
            <InputLabel
            id='custom-select-label'
            >
                {label}
            </InputLabel>
            <Select
            value={value}
            labelId='custom-select-label'
            id='custom-select'
            label={label}
            multiple
            MenuProps={{
                sx:{
                    maxHeight:'300px'
                }
            }}
            onChange={onChange}
            renderValue={(selected) => {
                return(
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>)
            }}
            >
                {
                    renderMenu.map((item)=>{
                        return(
                            <MenuItem
                            key={item}
                            value={item}
                            children={item}
                            />
                        )
                    })
                }
            </Select>
        </FormControl>
    )
}
const HousekeepingGrid = ({data,staff,refetch,noEdit}) => {
    const roomNumber = [
        '101',
        '102',
        '201',
        '202',
        '203',
        '204',
        '205',
    ]
    const fields = [
        ['date','日期'],
        ...(roomNumber.map(v=>([v,v]))),
        ['room_iscleaning','趕房順序'],
        ['room_iscleaning_count','趕房間數'],
        ['breakfast_count','用餐人數'],
        ['isArranged','安排完成'],
        ['workload_arrange','打掃間數'],
        ['room_iscleaning_arrange','必掃房型'],
        ['housekeeper_arrange','房務人員'],
        ['edit','編輯'],
    ]
    const stafflist = staff.map(({username})=>username)
    const navigate = useNavigate()
    const [update,setUpdate] = React.useState(JSON.parse(JSON.stringify(data)))
    const [openIndex,setOpenIndex] = React.useState(null)
    const [edit,setEdit] = React.useState(false)
    const [error,setError] = React.useState(null)
    const handleOrderButton = (id) => {
        if(id){
            navigate('/order/'+id)
        }
    }
    const handleSetUpdate = (index,key,value) => {
        if(!edit){
            setEdit(true)
        }
        setUpdate((prev)=>{
            prev[index][key] = value
            return [...prev]
        })
    }
    const handleUpdate = async () => {
        axios.defaults.withCredentials = true
        try {
           const newData = await axios.post(process.env.REACT_APP_API_ADDRESS+'/setting/sheet/housekeeping',update)   
           setUpdate(newData.data)
           setEdit(false)
        } catch (error) {
            setError(error.response.data.message)
        }
    }
    React.useEffect(()=>{
        const target = document.getElementById('hk-today')
        target?.scrollIntoView({inline:'start'})
    },[])
    return(
        <div
        className='housekeeping-grid'
        >
        <Dialog
        fullWidth
        open={
            typeof openIndex === 'number'||
            error?
            true:
            false}
        onClose={()=>{
            setOpenIndex(null)
            setError(null)
        }}
        >
        {typeof openIndex === 'number'&&
        <List>
        <ListItem>
        {
        format(new Date(update[openIndex].date),'yyyy/MM/dd(iiii)',{locale:zhTW})
        }
        </ListItem>
        <ListItem
        sx={{
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-start',
            gap:1
        }}
        >
            <FormControlLabel
            label='指定打掃間數'
            control={
                <Switch
                 checked={update[openIndex].workload_arrange!==null?true:false}
                 onChange={e=>{
                    handleSetUpdate(openIndex,'workload_arrange',e.target.checked?0:null)}
                 }
                />
            }
            />
            {update[openIndex].workload_arrange!==null&&
            <TextField
            label='打掃間數'
            value={update[openIndex].workload_arrange.toString()}
            inputProps={{
                type:'number',
                min:0
            }}
            onChange={e=>handleSetUpdate(
                openIndex,
                'workload_arrange',
                Number(String(e.target.value).replace(/[.*/]/,''))
                )}
            />
            }
        </ListItem>
        <ListItem
        sx={{
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-start',
            gap:1
        }}
        >
            <FormControlLabel
            label='自訂用餐人數'
            control={
                <Switch
                 checked={
                    typeof update[openIndex].breakfast_count_arrange ==='number'?
                    true:
                    false
                }
                 onChange={e=>{
                    handleSetUpdate(
                        openIndex,
                        'breakfast_count_arrange',
                        e.target.checked?
                        update[openIndex].breakfast_count:
                        null
                        )}
                 }
                />
            }
            />
            {typeof update[openIndex].breakfast_count_arrange ==='number'&&
            <TextField
            label='自訂用餐人數'
            value={String(update[openIndex].breakfast_count_arrange||0)}
            inputProps={{
                type:'number',
                min:0
            }}
            onChange={e=>handleSetUpdate(
                openIndex,
                'breakfast_count_arrange',
                Number(String(e.target.value).replace(/[.*/]/,''))
                )}
            />
            }
        </ListItem>
        <ListItem>
            <CustomSelect
            label={fields.find((v)=>v[0]==='room_iscleaning_arrange')[1]}
            value={update[openIndex].room_iscleaning_arrange}
            onChange={e=>handleSetUpdate(
                openIndex,
                'room_iscleaning_arrange',
                e.target.value.sort((a,b)=>a-b)
            )}
            renderMenu={roomNumber}
            />
        </ListItem>
        <ListItem>
            <CustomSelect
            label={fields.find((v)=>v[0]==='housekeeper_arrange')[1]}
            value={update[openIndex].housekeeper_arrange}
            onChange={e=>{
                const housekeeper_arrange = e.target.value
                let addedHK = housekeeper_arrange.filter((username)=>!data[openIndex].housekeeper_arrange?.includes(username)) || []
                let removedHK = data[openIndex].housekeeper_arrange?.filter((username)=>!(housekeeper_arrange?.includes(username))) || []
                console.log({addedHK,removedHK})
                setUpdate((prev)=>{
                    const newUpdate = prev.concat()
                    newUpdate[openIndex] = {
                        ...newUpdate[openIndex],
                        housekeeper_arrange,
                        addedHK,
                        removedHK
                    }
                    return newUpdate
                })
                setEdit(true)
            }}
            renderMenu={stafflist}
            />
        </ListItem>
        </List>
        }
        {error&&
        <Paper
        sx={{
            p:1
        }}
        >
            <Typography
            color='error.main'
            paragraph
            >
            錯誤:<br/>
            {error}
            </Typography>
            
        </Paper>
        }
        </Dialog>
            <Button
            fullWidth
            variant='outlined'
            disabled={!edit}
            children='更新'
            onClick={()=>handleUpdate()}
            />
            <Box
            component='div'
            className='hk-bottom'
            sx={{
                '::-webkit-scrollbar':{
                    display:'block',
                    width: '800px',
                    height:'20px'
                    },
                '::-webkit-scrollbar-thumb':{
                    display:'block',
                    width:'100px',
                    borderRadius:'10px',
                    border:'6px solid transparent',
                    backgroundClip:'padding-box',
                    backgroundColor:blue[500]
                    
                }
            }}
            >
                <Box
                component='table'
                >
                <Box
                component='tbody'
                >
                {
                    fields.map((val1,i)=>{
                    const [key,field] = val1
                    return(
                    <Box
                    component='tr'
                    key={key}
                    >
                        <Box
                            component='td'
                            className='headcol'
                            >
                                {field}
                            </Box>
                        {
                            update.map((val2,j)=>{
                                const today = new Date()
                                const context = formatData(key,val2,j)
                                function formatData (key,_value,index) {
                                    let value = _value
                                    if(roomNumber.includes(key)){
                                        if(!value){
                                            return ''
                                        }
                                        const buttons = createButton(value,key)
                                        function createButton (value,key) {
                                            let result = [null,null]
                                            if(value.room_checkout?.[key]){
                                                result[0] = value.room_checkout[key]
                                            }
                                            if(value.room_checkin?.[key]){
                                                result[1] = value.room_checkin[key]
                                            }
                                            if(value.room_onservice?.[key]){
                                                result = [value.room_onservice[key]]
                                            }
                                            return  result
                                        }
                                        return(
                                            <div
                                            className='button-container'
                                            > 
                                            {buttons.length===2&&
                                            <>
                                                    <div
                                                    className='button-checkout'
                                                    style={{
                                                        backgroundColor:buttons[0]&&
                                                                        `#${buttons[0]?.match(/[\w]{6}$/)?.[0]}`
                                                    }}
                                                    onClick={()=>handleOrderButton(buttons[0])}
                                                    />
                                                    <div
                                                    className='button-checkin'
                                                    style={{
                                                        backgroundColor:buttons[1]&&
                                                                        `#${buttons[1]?.match(/[\w]{6}$/)?.[0]}`
                                                    }}
                                                    onClick={()=>handleOrderButton(buttons[1])}
                                                    />
                                            </>
                                            }
                                            {buttons.length===1&&
                                                    <div
                                                    className='button-onservice'
                                                    style={{
                                                        backgroundColor:buttons[0]&&
                                                                        `#${buttons[0]?.match(/[\w]{6}$/)?.[0]}`
                                                    }}
                                                    onClick={()=>handleOrderButton(buttons[0])}
                                                    />
                                            }
                                            </div>
                                        )
                                    }
                                    value = _value?.[key]
                                    if(key==='date'){
                                        return format(new Date(value),'yyyy/MM/dd(iiii)',{locale:zhTW})
                                    }else if(key==='room_iscleaning_count'){
                                        return _value?.room_iscleaning?.length||0
                                    }else if(key==='breakfast_count'){
                                        return _value?.breakfast_count_arrange||_value?.breakfast_count||0
                                    }else if(key==='isArranged'){
                                        return (
                                            <div
                                            style={{
                                                position:'absolute',
                                                width:'100%',
                                                height:'100%',
                                                backgroundColor:value?'lightgreen':'pink'
                                            }}
                                            />
                                        )
                                    }else if(key==='workload_arrange'){
                                        return typeof value === 'number'?
                                                value:
                                                '未指定'
                                    }else if(
                                        [
                                        'room_iscleaning',
                                        'room_iscleaning_arrange',
                                        'housekeeper_arrange'
                                        ].includes(key)
                                    ){
                                        return(
                                        <table
                                        className='inner-table'
                                        >
                                            <tbody>
                                                    {   
                                                    value.map((v,i)=>
                                                    <tr key={i}>
                                                        <td>
                                                            {v}
                                                        </td>
                                                    </tr>
                                                    )
                                                    }
                                            </tbody>
                                        </table>
                                        )
                                    }else if(key==='edit'){
                                        return <Button
                                                disabled={noEdit||isBefore(new Date(val2.date),today)}
                                                variant='contained'
                                                size='small'
                                                sx={{
                                                    margin:'2px 1px 2px 1px'
                                                }}
                                                onClick={()=>setOpenIndex(index)}
                                                >編輯</Button>
                                    }else{
                                        return ''
                                    }
                                }
                                const isToday =     (key==='date')&&
                                                    (new Date(val2.date).toDateString() === today.toDateString())
                            return(
                            <Box
                            component='td'
                            key={j}
                            id={isToday?'hk-today':undefined}
                            // sx={{
                            //     position:'relative'
                            // }}
                            >
                                {
                                    context
                                }
                            </Box>
                            )}
                            )
                        }
                    </Box>
                    )
                    }
                    )
                }
                </Box>
                </Box>
            </Box>
        </div>
    )
}
const Housekeeping = () => {
    const {user} = React.useContext(UserContext)
    const isAdmin = user.isAdmin
    const {data} = useFetch(process.env.REACT_APP_API_ADDRESS+'/setting/sheet/housekeeping')
    const {data:staff} = useFetch(process.env.REACT_APP_API_ADDRESS+'/staff')
  return (
    <div 
    className='housekeeping'
    style={{
        position:'relative',
        width:'100%',
        padding:'20px',
        border:'1px solid black',
        boxSizing:'border-box'
    }}
    >
        <Paper>
            {data.length>0&&
            <HousekeepingGrid
            data={data}
            staff={staff}
            noEdit={!isAdmin}
            />
            }
        </Paper>
    </div>
  )
}

export default Housekeeping