import axios from 'axios'
import * as React from 'react'
import { UserContext } from '../../context/UserContext'

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve()
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
  })
const Auth = () => {
  // const [error, setError] = React.useState(null)
  const loginButton = React.useRef()
  const {dispatch} = React.useContext(UserContext)
  React.useEffect(()=>{
      const src = 'https://accounts.google.com/gsi/client'
      loadScript(src)
        .then(()=>{
          /*global google*/
            google.accounts.id.initialize({
              client_id:process.env.REACT_APP_GOOGLE_CLIENT_ID,
              callback: async (res) => {
              try {
                const user = await axios.post(
                  process.env.REACT_APP_API_ADDRESS+'/auth/login',
                  {
                    method:'google',
                    credential:res.credential
                  },
                  {withCredentials:true}
                  ).then(({data})=>data)
                dispatch({
                  type:'LOGIN_SUCCESS',
                  payload:user
                })
              } catch (error) {
                console.log(error)
              }
              }
            })
            google.accounts.id.renderButton(
              loginButton.current,
              {
                text:"signin",
              }
            )
        })
      return(()=>{
        const scriptTag = document.querySelector(`script[src="${src}"]`)
        if(scriptTag){
          scriptTag.remove()
        }
      })
  //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <div className='auth'>
      <div ref={loginButton}/>
    </div>
  )
}

export default Auth