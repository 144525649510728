import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetch } from '../../hook/useFetch'
import { Box, Button, ButtonGroup, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, Input, InputAdornment, InputLabel, List, ListItem, ListItemText, MenuItem, Paper, Select, Switch, Tab, Tabs, TextField, Typography } from '@mui/material'
import { addDays, addMinutes, format, isAfter, isBefore, isSameDay, isSameMonth, subMinutes } from 'date-fns'
import DoubleCheckButton from '../doubleCheckButton/DoubleCheckButton.jsx'
import {Delete, KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material'
import { zhTW } from 'date-fns/locale'
import { UserContext } from '../../context/UserContext.js'
import AddItemButton from '../addItemButton/AddItemButton.jsx'
import axios from 'axios'
import DateRangePicker from '../dateRangePicker/DateRangePicker.jsx'
import AddWorkView from '../addWorkView/AddWorkView.jsx'

const StyledSubheader = ({children}) => {
    return(
        <Typography
        component='div'
        color='gray'
        variant='h7'
        marginBottom={2}
        >
        {children}
        </Typography>
    )
}
const StyledTextField = ({edit,label,objKey,value,type,handleUpdate,...props}) => {
    let inputProps = undefined
    if(type==='number'){
        inputProps= {
            type:'number',
            min:0
        }
    }
    return(
        <TextField
            {...props}
            label={label}
            inputProps={inputProps}
            size='small'
            value={
               (typeof value === 'number'&&
               type === 'number')?
               String(value):
               value
            }
            onChange={(e)=>{
                if(!edit){return}
                handleUpdate({
                    [objKey]:
                    type==='number'?Number(e.target.value):
                    e.target.value
                })
            }}
            />
    )
}
const PaymentListItem = ({isNew,userId,payment,onUpdate,onDelete,onClose,...props}) => {
    const {
        title,
        amount
    } = payment
    const [collapsed,setCollapsed] = React.useState(false)
    const [hasPaySchema,setHasPaySchema] = React.useState(false)
    const [update,setUpdate] = React.useState(payment)
    const [workingTimeInfo,setWorkingTimeInfo] = React.useState([])
    const [error,setError] = React.useState(null)
    const handleUpdate = (v) => {
        setUpdate((prev)=>({...prev,...v}))
    }
    async function getWorkingTime (startDate,endDate,userId) {
        try {
            const data = await axios.get(
                                process.env.REACT_APP_API_ADDRESS+'/setting/working_time/?',
                                {
                                    withCredentials:true,
                                    params:{
                                        userId,
                                        startDate,
                                        endDate
                                    }
                                }
                            ).then(({data})=>data)
            if(data?.length){
                setWorkingTimeInfo(data)
                handleUpdate(
                    {
                        paySchema:data[0],
                        amount:data[0].wage*data[0].workingTime
                    }
                )
            }
        } catch (error) {
           setError(error.response.data.message)
        }
    }
    function paySchemaTranslator(paySchema,outputType) {
        if(!paySchema){
            switch(outputType){
                case 'text':
                    return `自訂工資`
                case 'value':
                    return 'null'
                default:break
            }
        }
        const {
            rate,
            wage,
            workingTime
        } = paySchema
        const unit = {
            hour:'小時',
            day:'天',
            month:'月',
        }[rate]
        switch(outputType){
            case 'text':
                return `${workingTime}${unit}(${wage}元/${unit})，共${workingTime*wage}元`
            case 'value':
                return JSON.stringify(paySchema)
            default:break
        }
    }
    React.useEffect(()=>{
        setUpdate(payment)
    },[payment])
    return(
        <div
        {...props}
        >
        <Dialog
        open={error?true:false}
        onClose={()=>setError(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
            <DialogTitle
            id="alert-dialog-title"
            >錯誤</DialogTitle>
            <DialogContent>
                <DialogContentText
                id='alert-dialog-description'
                >{error}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                children='確定'
                onClick={()=>setError(null)}
                />
            </DialogActions>
        </Dialog>
        <ListItem>
        <ListItemText
        primary={isNew?'新增':title}
        secondary={isNew?'':'工資:'+(amount||'未設定')}
        />
        <IconButton
        onClick={()=>{
            if(isNew){
                onClose()
            }else{
                setCollapsed(prev=>!prev)
            }
        }}
        >
            {isNew||collapsed?
                <KeyboardArrowUpRounded/>:
                <KeyboardArrowDownRounded/>
            }
        </IconButton>
        </ListItem>
        <Collapse
        in={isNew||collapsed}
        >
            <ListItem
            sx={{
                flexDirection:'column',
                alignItems:'flex-start',
                gap:2
            }}
            >
                <StyledTextField
                label='名稱'
                objKey='title'
                value={update.title}
                edit={isNew}
                handleUpdate={handleUpdate}
                />
                {isNew&&
                <ButtonGroup>
                    <Button
                    variant={!hasPaySchema?'contained':'outlined'}
                    children='自訂工資'
                    onClick={()=>setHasPaySchema(false)}
                    />
                    <Button
                    variant={hasPaySchema?'contained':'outlined'}
                    children='計算工資'
                    onClick={()=>setHasPaySchema(true)}
                    />
                </ButtonGroup>
                }
                {hasPaySchema&&
                <>
                <DateRangePicker
                input={
                    update.paySchema?
                    {
                        startDate:new Date(update.paySchema.firstDate),
                        endDate:new Date(update.paySchema.lastDate)
                    }:
                    null
                }
                output={(date)=>
                getWorkingTime(
                        date.startDate,
                        date.endDate,
                        userId
                )
                }
                />
                <FormControl
                size='small'
                sx={{
                    minWidth:'210px'
                }}
                >
                    <InputLabel 
                    id='workinghour-label' 
                    children='工時'
                    />
                    <Select
                    label='工時'
                    labelId='workinghour-label'
                    value={paySchemaTranslator(update.paySchema,'value')}
                    onChange={(e)=>{
                        const value = JSON.parse(e.target.value)
                        let paySchema = value
                        handleUpdate({
                            paySchema,
                            amount: value?
                                    (paySchema.wage*paySchema.workingTime):
                                    0
                        })
                        if(!value){
                            setHasPaySchema(false)
                        }
                    }}
                    >
                        <MenuItem 
                        value='null'
                        children='自訂工資'
                        />
                        {workingTimeInfo.map((mtd,i)=>
                            <MenuItem
                            key={i}
                            value={paySchemaTranslator(mtd,'value')}
                            children={paySchemaTranslator(mtd,'text')}
                            />
                            )
                        }
                    </Select>
                </FormControl>
                </>
                }
                {!isNew&&
                <>
                <StyledTextField
                label='日期'
                value={
                    Array.from(
                    new Set(
                    [
                        payment.paySchema?.firstDate,
                        payment.paySchema?.lastDate,
                    ].filter((date)=>date)
                    .map((date)=>format(new Date(date),'yy/MM/dd'))
                    ))
                    .join('-')
                }
                />
                <StyledTextField
                sx={{
                    minWidth:210
                }}
                label='工時'
                multiline
                value={paySchemaTranslator(payment.paySchema,'text')}
                />
                </>
                }
                <StyledTextField
                label='工資（台幣）'
                objKey='amount'
                type='number'
                value={update.amount}
                edit={isNew}
                handleUpdate={handleUpdate}
                />
                <FormControlLabel
                label={update.isPaid?'已支付':'未支付'}
                control={
                    <Switch
                    checked={update.isPaid}
                    onChange={(e)=>{
                        // if(isNew){
                            handleUpdate({isPaid:e.target.checked})
                        // }else if(!isNew&&!update.isPaid){
                        //     onUpdate({...update,isPaid:true})
                        // }
                    }}
                    />
                }
                />
                <Typography
                variant='h8'
                color='error'
                children='若設定為"已支付"，將無法再進行任何更動！'
                />
            </ListItem>
            <ListItem>
                {
                    <DoubleCheckButton
                    actionName='確定'
                    action={()=>{
                        onUpdate(update)
                    }}
                    >
                    <Button
                    variant='contained'
                    color='primary'
                    children={isNew?'新增':'更新'}
                    sx={{
                        mr:1
                    }}
                    />
                    </DoubleCheckButton>
                }
                {(!isNew)&&
                    <Button
                    variant='contained'
                    color='error'
                    children={'刪除'}
                    sx={{
                        mr:1
                    }}
                    onClick={()=>{
                        onDelete()
                    }}
                    />
                }
            </ListItem>
        </Collapse>
        <Divider/>
        </div>
    )
}
// const PaymentRatio = ({data,onUpdate}) => {
//     const [update,setUpdate] = React.useState(data)
//     const [edit,setEdit] = React.useState(false)
//     return(
//         <Paper
//         sx={{
//             p:2,
//         }}
//         >
//             <StyledSubheader
//             children='支薪方式'
//             />
//             <Box
//             component='div'
//              sx={{
//                 display:'flex',
//                 alignItems:'center',
//                 gap:1
//                 }}
//             >
//             <TextField
//             sx={{
//                 flex:2
//             }}
//             value={
//                 String(
//                 update.amount
//                 )
//             }
//             onChange={(e)=>{
//                 if(!edit){return}
//                 // handleSetUpdate('paymentRatio',{amount:Number(e.target.value)})
//                 setUpdate((prev)=>({...prev,amount:Number(e.target.value)}))
//             }}
//             label='費用'
//             size='small'
//             InputProps={{
//                 endAdornment:<InputAdornment position='end'>元</InputAdornment>
//             }}
//             inputProps={{
//                 type:'number',
//                 min:0,
//                 step:1,
//             }}
//             />
//             <ButtonGroup
//             sx={{
//                 flex:2
//             }}
//             >
//                 {
//                     [
//                         ['時','hour'],
//                         ['日','day'],
//                         ['月','month'],
//                     ].map((v)=>{
//                         return(
//                             <Button
//                             variant={
//                                 (
//                                     update.timespan
//                                 ) === v[1]
//                                 ?'contained':'outlined'}
//                             key={v[1]}
//                             children={v[0]}
//                             onClick={()=>{
//                                 if(!edit){return}
//                                 setUpdate(prev=>({...prev,timespan:v[1]}))
//                             }}
//                             />
//                         )
//                     })
//                 }
//             </ButtonGroup>
//             </Box>
//             <Box
//                 sx={{
//                     mt:2,
//                     display:'flex',
//                     gap:1,
//                     justifyContent:'flex-start'
//                 }}
//             >
//             {!edit&&
//             <Button
//             sx={{
//                 width:'30%',
//                 boxSizing:'border-box',
//             }}
//             variant='contained'
//             children='編輯'
//             onClick={()=>{
//                 setEdit(true)
//             }}
//             />
//             }
//             {edit&&
//                 <>
//                 <Button
//                 variant='contained'
//                 children='更新'
//                 onClick={async()=>{
//                     try {
//                         const res = await onUpdate()
//                         setUpdate(res.paymentRatio)
//                         setEdit(false)
//                     } catch (error) {
//                         console.log(error)
//                     }
//                 }}
//                 />
//                 <Button
//                 variant='contained'
//                 children='取消'
//                 onClick={()=>{
//                         setUpdate(data)
//                         setEdit(false)
//                 }}
//                 />
//                 </>
//             }
//              </Box>
//         </Paper>
//     )
// }
const WorkListItem = ({work,index,isSameUser,isAdmin,staff,onUpdate,noAcceptBtn}) => {
    // const isRoutine = work?.type?true:false
    const workKey = work?.type && {
        'default':'work_default',
        'date':'work_casual',
        'week':'work_routine',
        'month':'work_routine',
    }[work.type]
    const secondText = () => {
        const time =    [work?.startTime,work?.endTime].some(t=>t?true:false)?
                        '：'+work.startTime+'-'+work.endTime:
                        ''
        let firstPart
        if(work.type === 'default'){
            firstPart = '預設'
        }
        if(work.type === 'date'){
            firstPart = format(new Date(work.date),'MM/dd')
        }
        if(work.type==='week'){
            const weekday = [
                '日',
                '一',
                '二',
                '三',
                '四',
                '五',
                '六'
            ]
            firstPart = '每周'+work.workday.map((day)=>weekday[day]).join(',')
        }
        if(work.type==='month'){
            firstPart = '每月'+work.workday.join(',')+'號'
        }
        return firstPart+time
    }
    return(
        <ListItem
        divider
        >
            <ListItemText
            primary={work.title}
            secondary={secondText()}
            />
            {!noAcceptBtn&&
            (
            isSameUser?
            <FormControlLabel
            control={
            <Switch
            checked={work?.isAccepted?true:false}
            onChange={(e)=>{
                let _work = staff[workKey].concat()
                _work[index].isAccepted = e.target.checked
                onUpdate({[workKey]:_work})
            }}
            />
            }
            />:
            <Box
            component='div'
            sx={{
                borderRadius:2,
                pl:1,
                pr:1,
                bgcolor:work?.isAccepted?'success.main':'warning.main',
                color:'white'
            }}
            >
            <Typography
            children={work?.isAccepted?'已接受':'未接受'}
            />
            </Box>
            )
            }
            {
            isAdmin&&
            <DoubleCheckButton
            icon={<Delete/>}
            actionName='確定刪除?'
            action={()=>{
                const _work = staff[workKey]
                onUpdate({[workKey]:_work.filter((v,j)=>index!==j)})
            }}
            />
            }
        </ListItem>
    )
}
const StaffView = () => {
    axios.defaults.withCredentials = true
    const navigate = useNavigate()
    const {id} = useParams()
    const {user} = React.useContext(UserContext)
    const isAdmin = user.isAdmin
    const isSameUser = user.userId === id
    const [tabIndex,setTabIndex] = React.useState(0)
    const [openAW,setOpenAW] = React.useState(false)
    const actionAlias = {
        clockin:'打卡上班',
        clockout:'打卡下班'
    }
    let {data,refetch} = useFetch(process.env.REACT_APP_API_ADDRESS+'/staff/'+id)
    const staff = data?._id?data:null
    const [update,setUpdate] = React.useState()
    const [isAddingPayment,setIsAddingPayment] = React.useState(false)
    const newPayment = {
        title:'',
        paySchema:null,
        amount:0,
        isPaid:false,
        paidAt:new Date()
    }
    const handleUpdate = async (update) => {
      try {
        await axios.put(process.env.REACT_APP_API_ADDRESS+'/staff/'+id,update)
        refetch()
      } catch (error) {
        console.log(error)
      }
    }
    const deleteStaff = async () => {
        try {
            await axios.delete(process.env.REACT_APP_API_ADDRESS+'/staff/'+id)
            navigate('/staff')
        } catch (error) {
            console.log(error)
        }
    }
    const activateStaff = async (id,activate) => {
        try {
            await axios.post(process.env.REACT_APP_API_ADDRESS+'/staff/activate',{id,activate})
            refetch()
        } catch (error) {
            console.log(error)
        }
    }
    function getHour (startDate,endDate,log) {
        const start = subMinutes(new Date(startDate),1)
        const end = addMinutes(new Date(endDate),1)
        const firstLog = log.find(({time,action})=>{
            const date = new Date(time)
            return (isAfter(date,start))&&(action==='clockin')
        })
        const lastLog = log.reverse().find(({time,action})=>{
            const date = new Date(time)
            return (isBefore(date,end))&&(action==='clockout')
        })
        if(firstLog&&lastLog){
            return lastLog.accHour - firstLog.accHour
        }else{
            return 0
        }
    }
  return (
    <div
    className='staffView'
    >
        {openAW&&
        <AddWorkView
        open={openAW}
        onClose={()=>{
            setOpenAW(false)
            refetch()
        }}
        staffIds={[id]}
        />
        }
        <Typography
        variant='h6'
        m={1}
        color='gray'
        children={`員工姓名：${staff?.username} (${user.isStaff?'現職':'離職'})`}
        />
        <Tabs
        value={tabIndex}
        onChange={(v,i)=>{
            if(i===4){
                //打卡功能
            }
            setTabIndex(i)
        }}
        >
            <Tab label='基本'/>
            <Tab label='出勤'/>
            <Tab label='結算'/>
        </Tabs>
        {staff&&
        <Box
        component='div'
        sx={{
            display:'flex',
            flexDirection:'column',
            gap:1
        }}
        >
        {tabIndex===0&&
        <List>
        {isAdmin&&
        <ListItem
        sx={{
            gap:1
        }}
        >
            <DoubleCheckButton
            actionName='確定?'
            action={()=>activateStaff(id,staff?.isActivated?false:true)}
            >
                <Button
                variant='contained'
                color='warning'
                children={staff?.isActivated?'暫停員工身分':'恢復員工身分'}
                />
            </DoubleCheckButton>
            <DoubleCheckButton
            actionName='確定刪除'
            action={()=>deleteStaff()}
            >
                <Button
                variant='contained'
                color='error'
                children='強制刪除'
                />
            </DoubleCheckButton>
        </ListItem>
        }
        {/* <ListItem
        sx={{
            gap:1
        }}
        >
            <TextField
            size='small'
            label='LINE ID'
            id='input-staff-line-id'
            value={(update||staff)?.lineId||''}
            onChange={(e)=>setUpdate({lineId:e.target.value})}
            />
            {update?.lineId&&
            <Button
            variant='contained'
            children='更新'
            onClick={()=>{
                handleUpdate({lineId:update?.lineId})
                setUpdate()
            }}
            />
            } 
            {staff?.lineId&&
            <Button
            variant='contained'
            children='前往 LINE'
            onClick={()=>{
                window.open('https://line.me/ti/p/~'+staff?.lineId)
            }}
            />}
        </ListItem> */}
        <ListItem>
        <Paper
        sx={{
            width:1,
            p:2,
            position:'relative'
        }}
        >
            <StyledSubheader
            children='工作'
            />
            {isAdmin&&
            <AddItemButton
            onClick={()=>setOpenAW(true)}
            sx={{
                mt:2
            }}
            />
            }
            <List>
            {staff?.work_default?.map((work,index)=>
            <WorkListItem
            key={work._id}
            work={work}
            index={index}
            isSameUser={isSameUser}
            isAdmin={isAdmin}
            staff={staff}
            onUpdate={handleUpdate}
            noAcceptBtn={true}
            />
            )}
            {staff?.work_routine?.map((work,index)=>
            <WorkListItem
            key={work._id}
            work={work}
            index={index}
            isSameUser={isSameUser}
            isAdmin={isAdmin}
            staff={staff}
            onUpdate={handleUpdate}
            />
            )}
            {staff?.work_casual?.map((work,index)=>
            <WorkListItem
            key={work._id}
            work={work}
            index={index}
            isSameUser={isSameUser}
            isAdmin={isAdmin}
            staff={staff}
            onUpdate={handleUpdate}
            />
            )}
            </List>
        </Paper>
        </ListItem>
        </List>
        }
        {tabIndex===1&&
        <Paper
        sx={{
            p:2
        }}
        >
            <List>
                {staff?.log?.length>0&&
                <ListItem>
                    <Typography
                    children={`本月時數：${
                        getHour(
                            new Date(new Date().getFullYear(),new Date().getMonth(),1),
                            new Date(new Date().getFullYear(),new Date().getMonth()+1,0),
                            staff.log
                        )
                    } 小時`}
                    />
                </ListItem>
                }
                {
                    staff?.log?.reverse().map((log,i)=>{
                        const action = actionAlias[log.action]
                        const time = format(new Date(log.time),'yy/MM/dd(iiii) HH:mm',{locale:zhTW})
                        return(
                            <ListItem
                            key={i}
                            >
                                <ListItemText
                                primary={time+' : '+action}
                                />
                            </ListItem>
                        )
                    })
                }
            </List>
        </Paper>
        }
        {tabIndex===2&&
            <Paper
            sx={{
                p:2
            }}
            >
                <List>
                    {isAdmin&&(
                    !isAddingPayment?
                    <AddItemButton
                    onClick={()=>setIsAddingPayment(true)}
                    />:
                    <PaymentListItem
                    isNew={true}
                    userId={id}
                    payment={newPayment}
                    onUpdate={(update)=>{
                        handleUpdate({payment:[...staff.payment,update]})
                        setIsAddingPayment(false)
                    }}
                    onClose={()=>{
                        setIsAddingPayment(false)
                    }}
                    />)
                    }
                    {staff?.payment?.map((p,i)=>{
                        return(
                           <PaymentListItem
                           payment={p}
                           onUpdate={(update)=>{
                            let newPayment = staff.payment.concat()
                            newPayment[i] = update
                            handleUpdate({payment:newPayment})
                            }}
                           onDelete={()=>{
                            let newPayment = staff.payment.concat()
                            newPayment.splice(i,1)
                            handleUpdate({payment:newPayment})
                           }}
                           key={i}
                           />
                        )
                    })
                    }
                </List>
            </Paper>
        }
        </Box>
        }
    </div>
    // }
    // </>
  )
}

export default StaffView