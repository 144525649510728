import { Button, ButtonGroup, Dialog, Divider, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Typography, styled } from '@mui/material'
import { Box } from '@mui/system'
import { DateCalendar, LocalizationProvider, PickersDay } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { format, isSameDay } from 'date-fns'
import React from 'react'
import DoubleCheckButton from '../doubleCheckButton/DoubleCheckButton'
import { Delete } from '@mui/icons-material'
import { useFetch } from '../../hook/useFetch'
import axios from 'axios'
const StyledSelect = ({hours,values,setValues,objKey,disabledValues}) => {
    return(
        <FormControl
                sx={{
                    flex:1
                }}
                >
                <Select
                size='small'
                value={values[objKey]}
                MenuProps={{
                    sx:{
                        maxHeight:300
                    }
                }}
                onChange={(e)=>{
                    setValues((prev)=>{
                        return {...prev,[objKey]:e.target.value}
                    })
                }}
                >
                    {hours.map((v)=>
                    <MenuItem
                    key={v}
                    disabled={disabledValues?.includes(v)?true:false}
                    value={v}
                    children={v}
                    />
                    )
                    }
                    
                </Select>
                </FormControl>
    )
}
const MonthSelect = ({maxDay,values,onChange}) =>{
    maxDay = maxDay||25
    const days = new Array(maxDay).fill().map((v,i)=>1+i)
    return(
        <FormControl>
            <Select
            size='small'
            value={values}
            multiple
            MenuProps={{
                sx:{
                    height:300
                }
            }}
            onChange={onChange}
            >
                {days.map((v,i)=>
                <MenuItem
                key={i}
                value={v}
                children={v+'日'}
                />
                )
                }
            </Select>
        </FormControl>
    )
}
const WeekSelect = ({values,onChange}) =>{
    const week = [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
    ]
    return(
        <FormControl>
            <Select
            size='small'
            value={values}
            multiple
            MenuProps={{
                sx:{
                    height:300
                }
            }}
            onChange={onChange}
            >
                {week.map((v,i)=>
                <MenuItem
                key={i}
                value={i}
                children={v}
                />
                )
                }
            </Select>
        </FormControl>
    )
}
const DateSelect = ({values,onChange}) =>{
    const CustomCalendar = React.useMemo(()=>{
        const CustomPickersDay = styled(PickersDay,{
            shouldForwardProp: (prop) => 
            prop!=='isSelected'
        })(({theme,isSelected,day}) => ({
            borderRadius:0,
            ...(isSelected&&
                {
                    backgroundColor:theme.palette.primary.main,
                    color:theme.palette.primary.contrastText,
                    '&:hover, &:focus': {
                        backgroundColor: theme.palette.primary.main,
                      }
                })
        }))
        function Day(props){
            const {day,selectedDay,...other} = props
            return(
                <CustomPickersDay
                {...other}
                sx={{ px:2.5}}
                disableMargin
                day={day}
                selected={false}
                isSelected={selectedDay.some((d)=>isSameDay(new Date(d),day))}
                />
            )
        }
        return(
            <DateCalendar
            disableHighlightToday
            reduceAnimations
            showDaysOutsideCurrentMonth
            slots={{day:Day}}
            value={values[values.length-1]||new Date()}
            onChange={(newValue)=>{
                let _values = values.concat()
                const theIndex = values.findIndex((d)=>isSameDay(new Date(d),newValue))
                if(theIndex===-1){
                    onChange(values.concat(newValue))
                }else{
                    _values.splice(theIndex,1)
                    onChange(_values)
                }
            }}
            slotProps={{
                day:()=>({
                    selectedDay:values
                })
            }}
            />
        )
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[values])
    return(
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box
            sx={{
                p:1,
                borderRadius:'10px',
                minHeight:'10px',
                border:'1px solid',
                borderColor:theme=>theme.palette.primary,
                display:'flex',
                flexWrap:'wrap',
                gap:1
            }}
            >
                {
                    values.map((v,i)=>
                    <Button
                    key={i}
                    size='small'
                    variant='outlined'
                    onClick={()=>{
                        onChange(values.filter((d)=>!isSameDay(d,v)))
                    }}
                    >
                        {format(v,'MM/dd')}
                    </Button>
                    )
                }
            </Box>
            {CustomCalendar}
        </LocalizationProvider>
    )
}
const AddWorkView = ({open,onClose,staffIds}) => {
    axios.defaults.withCredentials = true
    const hours = createHours()
    const defaultVals = {
        title:'工作',
        typeAndDay:{
            type:'date',
            workday:[],
            date:[],
            // isRoutine:false
        },
        time:{
            startTime:'08:00',
            endTime:'12:00'
        }
    }
    const [title,setTitle] = React.useState(defaultVals.title)
    const [typeAndDay,setTypeAndDay] = React.useState(defaultVals.typeAndDay)
    const [time,setTime] = React.useState(defaultVals.time)
    const [tempIndex,setTempIndex] = React.useState('')
    const {
        data:workTemplate,
        refetch
    } = useFetch(process.env.REACT_APP_API_ADDRESS+'/setting/work_template')
    const ratioSchema = [
        {
            alias:'預設',
            value:'default'
        },
        {
            alias:'日期',
            value:'date'
        },
        {
            alias:'每周',
            value:'week',
            selection:new Array(7).fill().map((v,i)=>0+i)
        },
        {
            alias:'每月',
            value:'month',
            selection:new Array(25).fill().map((v,i)=>1+i)
        }
    ]
    function createHours(){
        return new Array(13).fill().map((v,i)=>{
            return `${6+i}`.padStart(2,'0')+':00'
        })
    }
    const handleWorkUpdate = async (_work) => {
        try {
            let {
                workday,
                date,
                ...theWork
            } = _work
            // theWork.isRoutine = ['default','week','month'].includes(theWork.type)?true:false
            let works = []
            if(['week','month'].includes(theWork.type)){
                theWork = {
                    ...theWork,
                    workday
                }
                works.push(theWork)
            }else if(theWork.type==='date'){
                works = date.map((date)=>({...theWork,date}))
            }else if(theWork.type==='default'){
                works.push(theWork)
            }
            await axios.post(process.env.REACT_APP_API_ADDRESS+'/setting/work/add',
            {
                staffIds,
                works
            })
            close()
        } catch (error) {
            console.log(error)
        }
    }
    const addTemplate = async () => {
        try {
            const work = {
                title,
                ...typeAndDay,
                ...time
            }
            if(!title){return}
            await axios.post(process.env.REACT_APP_API_ADDRESS+'/setting/work_template/add',work)
            refetch()
        } catch (error) {
            console.log(error)
        }
    }
    const deleteTemplate = async (id) => {
        try {
            await axios.delete(process.env.REACT_APP_API_ADDRESS+'/setting/work_template/'+id)
            refetch()
        } catch (error) {
            console.log(error)
        }
    }
    const close = () => {
        onClose()
        setTitle(defaultVals.title)
        setTypeAndDay(defaultVals.typeAndDay)
        setTime(defaultVals.time)
    }
  return (
    <Dialog
        open={open}
        onClose={()=>close()}
        sx={{
            '&>*>*':{
                width:'100vw'
            }
        }}
        >
        <Paper
            sx={{
                p:1,
                display:'flex',
                flexDirection:'column',
                gap:2,
                minWidth:'320px',
            }}
            >
                <Typography
                component='div'
                color='gray'
                variant='h6'
                children='賦予工作'
                />
                <FormControl
                size='small'
                >
                    <InputLabel
                    id='assigned-work-template-select'
                    >套用模板</InputLabel>
                    <Select
                    labelId='assigned-work-template-select'
                    label='可用模板'
                    value={tempIndex}
                    onChange={(e)=>{
                        const index = e.target.value
                        setTempIndex(index)
                        if(typeof index === 'number'){
                            const {
                                title:_title,
                                startTime:_startTime,
                                endTime:_endTime,
                                ..._typeAndDay
                            } = workTemplate[index]
                            setTitle(_title)
                            setTypeAndDay((prev)=>({
                                ...prev,
                                ..._typeAndDay
                            }))
                            setTime({
                                startTime:_startTime,
                                endTime:_endTime
                            })
                        }
                    }}
                    renderValue={(v)=>{
                        return workTemplate[v].title
                    }}
                    >
                        <MenuItem 
                        value=''
                        children='無'
                        />
                        {workTemplate.map((v,i)=>{
                        return(
                            <MenuItem
                            key={i}
                            value={i}
                            sx={{
                                display:'flex',
                                justifyContent:'space-between'
                            }}
                            >
                            <div>
                                {v.title}
                            </div>
                            <DoubleCheckButton
                            icon={<Delete/>}
                            actionName={'確定刪除'}
                            action={()=>{
                                deleteTemplate(v._id)
                            }}
                            />
                            </MenuItem>
                        )
                        })
                        }
                    </Select>
                </FormControl>
                <Divider/>
                <TextField
                label='工作名稱'
                id='staff-assign-work-title'
                size='small'
                value={title}
                onChange={(e)=>{
                    setTitle(e.target.value)
                }}
                />
                <ButtonGroup>
                    {ratioSchema.map((v,i)=>
                        <Button
                        variant={
                            typeAndDay.type===v.value?
                            'contained':'outlined'
                        }
                        key={i}
                        children={v.alias}
                        onClick={()=>{
                            setTypeAndDay(prev=>{
                                return(
                                    {
                                        ...prev,
                                        type:v.value,
                                        workday:[],
                                        date:[]
                                    }
                                )
                            })
                        }}
                        />                    
                    )
                    }
                </ButtonGroup>
                {typeAndDay.type==='date'&&
                <DateSelect
                values={typeAndDay.date}
                onChange={(values)=>setTypeAndDay((prev)=>({...prev,date:values}))}
                />
                }
                {typeAndDay.type==='week'&&
                <WeekSelect
                values={typeAndDay.workday}
                onChange={(e)=>setTypeAndDay((prev)=>({...prev,workday:e.target.value.sort((a,b)=>a-b)}))}
                />
                }
                {typeAndDay.type==='month'&&
                <MonthSelect
                values={typeAndDay.workday}
                onChange={(e)=>setTypeAndDay((prev)=>({...prev,workday:e.target.value.sort((a,b)=>a-b)}))}
                />
                }
                <Box
                component='div'
                sx={{
                    display:'flex',
                    alignItems:'center'
                }}
                >
                    <StyledSelect
                    hours={hours}
                    values={time}
                    setValues={setTime}
                    objKey='startTime'
                    />
                    <pre> - </pre>
                    <StyledSelect
                    hours={hours}
                    values={time}
                    setValues={setTime}
                    objKey='endTime'
                    disabledValues={hours.filter((hour)=>{
                        return Number(hour.split(':')[0])<Number(time.startTime.split(':')[0])
                    })}
                    />
                </Box>
                <Box>
                    <Button
                    variant='contained'
                    children='賦予給選擇'
                    onClick={()=>handleWorkUpdate({
                        title,
                        ...typeAndDay,
                        ...time
                    })}
                    sx={{
                        mr:1
                    }}
                    />
                    <Button
                    disabled={workTemplate.some((temp)=>temp.title===title)?true:false}
                    variant='contained'
                    children='儲存為模板'
                    onClick={()=>addTemplate()}
                    />
                </Box>
                
        </Paper>
    </Dialog>
  )
}

export default AddWorkView