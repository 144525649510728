import { Button, ButtonGroup, Checkbox, Dialog, Divider, Fab, FormControl, FormControlLabel, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, Paper, Select, Switch, TextField, Typography, styled } from '@mui/material'
import { addDays, differenceInDays, format, isAfter, isBefore, isSameDay } from 'date-fns'
import React from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import DateRangePicker from '../../component/dateRangePicker/DateRangePicker.jsx'
import { Box } from '@mui/system'
import { Add, Delete, MobileOff, WarningRounded } from '@mui/icons-material'
import DoubleCheckButton from '../../component/doubleCheckButton/DoubleCheckButton.jsx'
import { DateCalendar, DatePicker, LocalizationProvider, PickersDay } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import AddWorkView from '../../component/addWorkView/AddWorkView.jsx'
import AddItemButton from '../../component/addItemButton/AddItemButton.jsx'
import axios from 'axios'
import { useFetch } from '../../hook/useFetch.js'
const StaffListItem = ({info,checked,onClick,onCheck,...props}) => {
    const {
        username,
        work_casual
    } = info
    const hasWorkNotAccepted =  info.work_casual.some(({isAccepted})=>!isAccepted)||
                                info.work_routine.some(({isAccepted})=>!isAccepted)
    function compactAW (work_casual,limit=3) {
        let works = []
        for(const work of work_casual){
            const date = format(new Date(work.date),'MM/dd')
            const form = {
                date,
                works:[work.title]
            }
            if(
                works.length===0||
                works[works.length-1].date!==date
                ){
                works.push(form)
            }else{
                works[works.length-1].works.push(work.title)
            }
            if(works.length>=limit){
                break
            }
        }
        let text =  works.reduce((acc,cur,index)=>{
            return  acc+
                    (index?'、':'')+
                    `${cur.date}:${cur.works.join(',')}`
        },'')
        const textCount = 20
        const regex = new RegExp(`^[.,\\w\\W\\d\\D\\s\\S]{${textCount-3}}`)
        text = text.length>textCount?
        text.match(regex)[0]+'...':
        text
        return text
    }
    return(
        <ListItem
        {...props}
        divider
        >
            <FormControlLabel
            control={
            <Checkbox
            checked={checked}
            onChange={onCheck}
            />
            }
            />
            <ListItemButton
            onClick={onClick}
            >
            <ListItemText
            primary={username}
            secondary={
                compactAW(work_casual,3)
            }
            />
            {hasWorkNotAccepted&&
            <WarningRounded
            color='warning'
            />
            }
            </ListItemButton>
        </ListItem>
    )
}
const Staff = () => {
    const {_id} = useParams()
    const navigate = useNavigate()
    const [selected,setSelected] = React.useState([])
    const [openAW,setOpenAW] = React.useState(false)
    const {data,refetch} = useFetch(process.env.REACT_APP_API_ADDRESS+'/staff')
    const handleClick = (_id) => {
        navigate(_id)
    }
    const handleCheck = (_id) => {
        setSelected((prev)=>{
            if(prev.includes(_id)){
                return prev.filter((v)=>v!==_id)
            }else{
                return prev.concat([_id])
            }
        })
    }
    async function addStaff (){
        try {
            axios.defaults.withCredentials = true
            const mobile = document.getElementById('input-add-staff-mobile')?.value
            if(mobile){
            await axios.post(
                process.env.REACT_APP_API_ADDRESS+
                '/staff/add',
                {
                    mobile
                }
                )
            refetch()
            }
        } catch (error) {
            console.log(error)
        }
    }
    async function removeOutdatedWorkFromStaff () {
        axios.defaults.withCredentials = true
        try {
            await axios.post(process.env.REACT_APP_API_ADDRESS+'/staff/remove_outdated_work')
            refetch()
        } catch (error) {
            console.log(error)
        }
    }
  return (
    <>
    <Outlet/>
    <div
    className='staff'
    hidden={_id?true:false}
    >
        {
            <AddWorkView
            open={openAW}
            onClose={()=>setOpenAW(false)}
            staffIds={selected}
            />
        }
        <List>
            <ListItem
            sx={{
                justifyContent:'space-between'
            }}
            divider
            >
                <TextField
                size='small'
                label='新增員工（輸入員工手機）'
                id='input-add-staff-mobile'
                />
                <Button
                variant='contained'
                children='新增'
                onClick={()=>addStaff()}
                />
            </ListItem>
            <ListItem>
                <DoubleCheckButton
                action={()=>removeOutdatedWorkFromStaff()}
                actionName='刪除'
                >
                    <Button
                    variant='contained'
                    color='warning'
                    children='刪除所有員工過期的工作'
                    />
                </DoubleCheckButton>
            </ListItem>
            {data.map((staff)=>
            <StaffListItem
            key={staff._id}
            info={staff}
            onClick={()=>handleClick(staff._id)}
            checked={selected.includes(staff._id)}
            onCheck={()=>handleCheck(staff._id)}
            />
            )
            }
        </List>
        <Fab
        size='large'
        variant='extended'
        color='primary'
        sx={{
            position:'absolute',
            bottom:16,
            right:16,
            wordBreak:'keep-all',
        }}
        onClick={()=>{
            if(selected.length>0){
            setOpenAW(true)
            }
        }}
        >
            <Add/>
            新增工作
        </Fab>
    </div>

    </>
  )
}

export default Staff