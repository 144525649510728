import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import OrderView from '../../component/orderView/OrderView'

const Order = ({hasCompleted}) => {
    const {_id} = useParams()
    const navigate = useNavigate()
  return (
    <div>
        <OrderView
        orderId={_id}
        close={()=>{
          navigate(-1)
        }}
        // hasCompleted={hasCompleted}
        />
    </div>
  )
}

export default Order