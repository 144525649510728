import { Button, ButtonGroup, ClickAwayListener, Collapse, Divider, FormControl, FormControlLabel,IconButton, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, Paper, Select, Switch, Tab, Tabs, TextField, Typography } from '@mui/material'
import React from 'react'
import StyledSubheader from '../../component/styledSubheader/StyledSubheader'
import AddItemButton from '../../component/addItemButton/AddItemButton'
import { Box } from '@mui/system'
import { CopyAll, Delete } from '@mui/icons-material'
import { useFetch } from '../../hook/useFetch'
import { addDays, format, isBefore, subDays } from 'date-fns'
import axios from 'axios'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import CalendarList from '../../component/calendarList/CalendarList'
import ToTopButton from '../../component/toTopButton/ToTopButton'
import DateRangePicker from '../../component/dateRangePicker/DateRangePicker'
import { useNavigate } from 'react-router-dom'

const Calendar = () => {
    axios.defaults.withCredentials=true
    const [dates,setDates] = React.useState({
        startDate:new Date(new Date().toDateString()),
        endDate:new Date(addDays(new Date(),30).toDateString())
    })
    const {
        data:pads,
        refetch:padsRefetch
    }=useFetch(process.env.REACT_APP_API_ADDRESS+'/setting/pads')
    const {
        data:setting,
        refetch:settingRefetch
    }=useFetch(process.env.REACT_APP_API_ADDRESS+'/setting')
    const {
        data:calendar,
        refetch:calendarRefetch
    }=useFetch(process.env.REACT_APP_API_ADDRESS+'/info/calendar/',dates)
    const navigate = useNavigate()
    const calendarList = setting?.calendar || []
    const newSchema = {
        name:'新方案',
        priceSchema:[
            {
                roomNumber:'101',
                closed:false,
                weekdayPrice:3000,
                weekendPrice:3300,
                canAddPerson:false,
                addPersonPrice:0,
                specialPrice:[]
            },
            {
                roomNumber:'102',
                closed:false,
                weekdayPrice:3300,
                weekendPrice:3800,
                canAddPerson:false,
                addPersonPrice:0,
                specialPrice:[]
            },
            {
                roomNumber:'201',
                closed:false,
                weekdayPrice:4500,
                weekendPrice:5000,
                canAddPerson:true,
                addPersonPrice:300,
                specialPrice:[]
            },
            {
                roomNumber:'202',
                closed:false,
                weekdayPrice:4500,
                weekendPrice:5000,
                canAddPerson:true,
                addPersonPrice:300,
                specialPrice:[]
            },
            {
                roomNumber:'203',
                closed:false,
                weekdayPrice:4000,
                weekendPrice:4500,
                canAddPerson:true,
                addPersonPrice:300,
                specialPrice:[]
            },
            {
                roomNumber:'204',
                closed:false,
                weekdayPrice:4500,
                weekendPrice:5000,
                canAddPerson:true,
                addPersonPrice:300,
                specialPrice:[]
            },
            {
                roomNumber:'205',
                closed:false,
                weekdayPrice:4500,
                weekendPrice:5000,
                canAddPerson:true,
                addPersonPrice:300,
                specialPrice:[]
            },
        ],
        daySchema:{
            weekday:[4,0],
            weekend:[5,6],
            closedDay:[1,2,3],
            closedDate:[],
            specialDate:[]
        },
    }
    const lastDate = !calendarList.length?new Date():new Date(calendarList[calendarList.length-1].endDate)
    // const [openPad,setOpenPad] = React.useState()
    const [tabIndex,setTabIndex] = React.useState(0)
    const gotoPad = (type,schema) => {
        navigate('/pad',{state:{type,schema}})
    }
    const PadsListItem = ({pad,disableDelete}) => {
        const [openDelete,setOpenDelete] = React.useState(false)
        const desc = [
            ['休',pad.daySchema.closedDay],
            ['平',pad.daySchema.weekday],
            ['假',pad.daySchema.weekend],
            ['特',pad.daySchema.specialDate.length],
            ['關',pad.daySchema.closedDate.length],
        ].map((v)=>v[0]+':'+v[1]).join('/')
        const removePad = async () => {
            try {
                await axios.delete(process.env.REACT_APP_API_ADDRESS+'/setting/pads/'+pad._id)
                padsRefetch()
            } catch (error) {
                console.log(error)
            }
        }
        const handleCreatePad = (type) => {
            let {_id,...newPad} = pad
            if(type==='new'){
                newPad.name = '複製_'+pad.name
            }else if(type==='update'){
                newPad._id = _id
            }
            // setOpenPad({type,schema:newPad})
            gotoPad(type,newPad)
        }
        return(
            <ClickAwayListener
            onClickAway={()=>setOpenDelete(false)}
            >
            <ListItem
            divider
            disablePadding
            secondaryAction={
                openDelete?
                <Button
                variant='contained'
                children='確認刪除'
                onClick={()=>removePad()}
                />:
                <>
                <IconButton
                onClick={()=>handleCreatePad('new')}
                >
                    <CopyAll/>
                </IconButton>
                <IconButton
                disabled={disableDelete}
                onClick={()=>setOpenDelete(true)}
                >
                    <Delete/>
                </IconButton>
                </>
            }
            >
                <ListItemButton
                onClick={()=>handleCreatePad('update')}
                >
                    <ListItemText 
                    primary={pad.name} 
                    secondary={desc}
                    />
                    
                </ListItemButton>
            </ListItem>
            </ClickAwayListener>
        )
    }
    const AddNewCalendar = ({schemas}) => {
        const today = new Date(new Date().toDateString())
        const defaultRes = {
            schema:'none',
            startDate:today,
            endDate:addDays(today,1),
            overwriteClosedDate:false
        }
        const [res,setRes] = React.useState(defaultRes)
        const [isEditing,setIsEditing] = React.useState(false)
        const handleUpdate = async () => {
            try {
                await axios.put(process.env.REACT_APP_API_ADDRESS+'/setting/calendar',{calendar:[res]})
                setIsEditing(null)
                settingRefetch()
            } catch (error) {
                console.log(error)
            }
        }
        const handleChange = (value) => {
            if(!isEditing){
                setIsEditing(true)
            }
            let newRes = {
                ...res,
                ...value
            }
            if(isBefore(newRes.endDate,newRes.startDate)){
                if(Object.keys(value)[0]==='startDate'){
                    newRes.endDate = addDays(newRes.startDate,1)
                }else{
                    newRes.startDate = subDays(newRes.endDate,1)
                }
            }
            setRes(newRes)
        }
        return(
            <List
            subheader={<StyledSubheader children='安排時段'/>}
            >
            <ListItem
            sx={{
                display:'flex',
                flexDirection:'column',
                alignItems:'flex-start'
            }}
            >
                <Typography
                fontSize={12}
                color='gray'
                children='選擇時段'
                sx={{
                    ml:1,
                    mb:1
                }}
                />
                <DateRangePicker
                input={
                    {
                        startDate:res.startDate,
                        endDate:res.endDate
                    }
                }
                output={(date)=>setRes((res)=>({...res,...date}))}
                />
            </ListItem>
            <ListItem>
            {
                res.schema?
                <FormControl>
                <InputLabel
                id="select-schema-label"
                >選擇計畫</InputLabel>
                <Select
                labelId="select-schema-label"
                id="select-schema"
                label="選擇計畫"
                value={res.schema}
                onChange={(e)=>handleChange({schema:e.target.value})}
                >
                    <MenuItem
                    value='none'
                    children='請選擇'
                    />
                    {
                        schemas.map((value)=>
                        <MenuItem
                        key={value.name}
                        value={value.name}
                        children={value.name}
                        />
                        )
                    }
                </Select>
                </FormControl>:
                <Typography
                children='請新增計畫'
                />
            }
            </ListItem>
            
            <ListItem
            sx={{
                display:'flex',
                flexDirection:'column',
                alignItems:'flex-start'
            }}
            >
                <FormControlLabel
                label='強制覆蓋'
                control={
                <Switch
                id='overwriteClosedDate'
                checked={res.overwriteClosedDate}
                onChange={(e)=>handleChange({overwriteClosedDate:e.target.checked})}
                />}
                />
                <Typography
                fontSize={14}
                color='gray'
                children='（若開啟，之前關閉的房間可能會變動!）'
                />
            </ListItem>
            {
            res.schema!=='none'&&
            isEditing&&
            <ListItem>
                <Button
                variant='contained'
                color='primary'
                children='套用'
                sx={{mr:1}}
                onClick={()=>handleUpdate()}
                />
                <Button
                variant='contained'
                color='error'
                children='取消'
                onClick={()=>{
                    setRes(defaultRes)
                    setIsEditing(false)
                }}
                />
            </ListItem>
            }
            </List>
        )
    }
    const DisplayDateRange = ({input,output}) => {
        const defaultInput = {
            ...input,
            startDate:input?.startDate&&new Date(input.startDate),
            endDate:new Date(input.endDate)
        }
        const [res,setRes] = React.useState(defaultInput)
        const [edit,setEdit] = React.useState(false)
    return(
        <List
        subheader={<StyledSubheader children='顯示的時段（客人可於網頁查詢）'/>}
        >
            <ListItem
            alignItems='flex-start'
            sx={{
                flexDirection:'column',
                gap:2
            }}
            >
            <ButtonGroup>
                <Button
                variant={res.startDate?'outlined':'contained'}
                children='起始日為今日'
                onClick={()=>setRes((prev)=>({...prev,startDate:undefined}))}
                />
                <Button
                variant={!res.startDate?'outlined':'contained'}
                onClick={()=>setRes((prev)=>({...prev,startDate:new Date(new Date().toDateString())}))}
                children='起始日為它日'
                />
            </ButtonGroup>
            <TextField
                    label='入住前幾天可預訂？'
                    type='number'
                    value={res.startDaysBeforeToday}
                    onChange={(e)=>{
                        let value = e.target.value
                        setRes((prev)=>({...prev,startDaysBeforeToday:value}))
                        setEdit(true)
                    }}
            />
            {res?.startDate?
                <DateRangePicker
                input={{
                    startDate:res.startDate,
                    endDate:res.endDate
                }}
                output={(date)=>{
                    setRes((prev)=>({...prev,...date}))
                    setEdit(true)
                }}
                />:
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                    label='最後顯示日期'
                    value={res.endDate}
                    format='yyyy/MM/dd'
                    onChange={(date)=>{
                        setRes((prev)=>({...prev,endDate:date}))
                        setEdit(true)
                    }}
                    />
                </LocalizationProvider>
            }
            </ListItem>
            <Collapse
            in={edit}
            >
                <ListItem
                sx={{gap:2}}
                >
                <Button
                disabled={
                    res.startDaysBeforeToday<1
                }
                variant='contained'
                color='primary'
                children='確定'
                onClick={()=>{
                    output(res)
                    setEdit(false)
                }}
                />
                <Button
                variant='contained'
                color='error'
                children='取消'
                onClick={()=>{
                    setRes(defaultInput)
                    setEdit(false)

                }}
                />
                </ListItem>
            </Collapse>
        </List>
        )
    }
    const updateSetting = async (req) => {
        try {
            await axios.put(process.env.REACT_APP_API_ADDRESS+'/setting',req)
            settingRefetch()
        } catch (error) {
            console.log(error)
        }
    }
  return (
    <div className='Calendar'
    style={{
        position:'relative',
        zIndex:0,
        marginBottom:24
    }}
    >
        <Box
        sx={{
            borderBottom:1,
            borderColor:'divider'
        }}
        >
            <Tabs
            value={tabIndex}
            onChange={(e,v)=>setTabIndex(v)}
            >
                <Tab label='日曆'/>
                <Tab label='顯示'/>
                <Tab label='安排'/>
                <Tab label='計畫'/>
            </Tabs>
        </Box>
        { tabIndex===0&&
        <div>
        <Paper
        sx={{
            p:2,
            zIndex:1
        }}
        >
            <DateRangePicker
            input={dates}
            output={(date)=>setDates(date)}
            minDate={new Date(new Date().toDateString())}
            maxDate={lastDate}
            />
        </Paper>
        <CalendarList
        calendar={calendar}
        refetch={calendarRefetch}
        />
        <ToTopButton
         triggerHeight={30}
         />
        </div>
        }
        { tabIndex===1&&
        <>
            {
            setting?.displayDateRange&&
            <DisplayDateRange
            input={setting.displayDateRange}
            output={(value)=>updateSetting({displayDateRange:value})}
            />
            }
            <Divider/>
            {/* <List
            subheader={<StyledSubheader children='已安排日期'/>}
            >
                {calendarList.map((v,i)=>{
                    return(
                        <ListItem
                        key={i}
                        divider
                        >
                            <ListItemText
                            primary={
                                [v.startDate,v.endDate].map((date)=>format(new Date(date),'yyyy/MM/dd')).join('-')}
                            secondary={'適用：'+v.schema}
                            />
                        </ListItem>
                    )
                })}
            </List>
            <Divider/>
            <AddNewCalendar
            schemas={pads}
            /> */}
            <Divider/>
            {/* <List
            subheader={<StyledSubheader children='建立計畫'/>}
            >
            {pads.map((item)=>{
            const disableDelete = calendarList.some(({schema})=>schema===item.name)
            return <PadsListItem
                    key={item.name}
                    pad={item}
                    disableDelete={disableDelete}
                    />
            })
            }
            </List>
            <AddItemButton
            text={'新增計畫'}
            onClick={()=>
                // setOpenPad({type:'new',schema:newSchema})
                gotoPad('new',newSchema)
            }
            /> */}
        </>
        }
        { tabIndex===2&&
        <>
            <List
            subheader={<StyledSubheader children='目前的時段'/>}
            >
                {calendarList.map((v,i)=>{
                    return(
                        <ListItem
                        key={i}
                        divider
                        >
                            <ListItemText
                            primary={
                                [v.startDate,v.endDate].map((date)=>format(new Date(date),'yyyy/MM/dd')).join('-')}
                            secondary={'適用：'+v.schema}
                            />
                        </ListItem>
                    )
                })}
            </List>
            <Divider/>
            <AddNewCalendar
            schemas={pads}
            />
        </>
        }
        { tabIndex===3&&
        <>
            <List
            subheader={<StyledSubheader children='計畫（透過安排才會生效）'/>}
            >
            {pads.map((item)=>{
            const disableDelete = calendarList.some(({schema})=>schema===item.name)
            return <PadsListItem
                    key={item.name}
                    pad={item}
                    disableDelete={disableDelete}
                    />
            })
            }
            </List>
            <AddItemButton
            text={'新增計畫'}
            onClick={()=>
                // setOpenPad({type:'new',schema:newSchema})
                gotoPad('new',newSchema)
            }
            />
        </>
        }
    </div>
  )
}

export default Calendar