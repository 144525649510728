import React from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import axios from 'axios'
import { Box } from '@mui/system'
import { format } from 'date-fns'
import { useFetch } from '../../hook/useFetch'

const ClockinQrCode = () => {
    const navigate = useNavigate()
    const {data,refetch} = useFetch(process.env.REACT_APP_API_ADDRESS+'/clockin/clockin_hash')
    const url = process.env.REACT_APP_ADMIN_ADDRESS+'/clockin/hash/?hash='+data[0]
    console.log(url)
  return (
    <div
    style={{
        width:'100%',
        height:'100%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    }}
    >
        <div
        className="qrcode"
        onClick={()=>{
            // navigate(url)
            window.open(url)
        }}
        >
            <QRCodeCanvas
            size={256}
            value={url}
            />
        </div>
    </div>
  )
}

export default ClockinQrCode