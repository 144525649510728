import React from 'react'
import { useFetch } from '../../hook/useFetch'
import { Box, Button, IconButton, List, ListItem, ListItemText, Paper, Tab, Tabs, TextField } from '@mui/material'
import ToTopButton from '../../component/toTopButton/ToTopButton'
import { Add, Delete } from '@mui/icons-material'
import CustomImageList from '../../component/customImageList/CustomImageList'
import axios from 'axios'

const Room = () => {
    const {data,refetch} = useFetch(process.env.REACT_APP_API_ADDRESS+'/info/room')
    const [tabIndex,setTabIndex] = React.useState(0)
    const [edit,setEdit] = React.useState(false)
    const [update,setUpdate] = React.useState(null)
    const field = [
        {title:'名稱',key:'name'},
        {title:'人數',key:'person',type:'number'},
        {title:'介紹',key:'desc'},
        {title:'樓層',key:'floor',type:'number'}
    //eslint-disable-next-line react-hooks/exhaustive-deps
    ]
    // const StyleListItem = ({title,value,onEdit,multiline,type}) => {
    //     return(
    //         <ListItem>
    //             <ListItemText
    //             primary={title}
    //             sx={{flex:1}}
    //             />
    //             {!edit?
    //             <ListItemText
    //             primary={value}
    //             sx={{flex:3}}
    //             />:
    //             <TextField
    //             type={type}
    //             sx={{flex:3}}
    //             multiline
    //             defaultValue={value}
    //             onChange={(e)=>setUpdate(e.target.value)}
    //             />
    //             }
    //         </ListItem>
    //     )
    // }
    const handleUpdate = async (input) => {
        try {
            await axios.put(
                process.env.REACT_APP_API_ADDRESS+'/setting/room/'+data[tabIndex].roomNumber,
                input,
                {withCredentials:true}
                )
            handleEdit(false)
            refetch()
        } catch (error) {
            console.log(error)
        }
    }
    const handleEdit = (input=false) => {
        if(!input){
            setUpdate(null)
        }
        setEdit(input?true:false)
    }
    const handleFeature = (type,index,value) => {
        let updatedFeature = update?.feature?.concat() || data?.[tabIndex]?.feature.concat()
        switch(type){
            case 'edit':
                updatedFeature[index] = value
                break
            case 'delete':
                updatedFeature = updatedFeature.filter((v,i)=>i!==index)
                break
            case 'add':
                updatedFeature.push('')
                break
            default:return
        }
        setUpdate((prev)=>(
            {
                ...prev,
                feature:updatedFeature
            }
        ))
    }
    // console.log(update?.feature)
  return (
    <div className='room'
    style={{
        paddingBottom:100
    }}
    >
    <Box
    sx={{ maxWidth:1}}
    >
        <Tabs
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        value={tabIndex}
        onChange={(e,v)=>{
            setEdit(false)
            setTabIndex(v)
        }}
        >
            {data.map((room)=>{
            return(
            <Tab 
            key={room._id}
            label={room.roomNumber}
            />
            )
            })}
        </Tabs>
    </Box>
    <Paper
    sx={{
        width:1,
        display:'flex',
        position:'sticky',
        top:0,
        borderRadius:0,
        zIndex:1
    }}
    >
        {
            edit&&
            <Button
            children='取消'
            onClick={()=>handleEdit(false)}
            />
        }
        <Button
        // fullWidth
        sx={{
            marginLeft:'auto',
        }}
        children={edit?'更新':'編輯'}
        onClick={()=>{
            if(!edit){
                handleEdit(true)
            }else{
                handleUpdate(update)
            }
        }}
        />
    </Paper>
    <List>
        {/* single value */}
        {field.map(({title,key,type})=>{
        const multiline = key==='desc'
        const value = update?.[key]||data?.[tabIndex]?.[key]
        return(
            <ListItem
                key={key}
                divider
            >
                <ListItemText
                primary={title}
                sx={{flex:1}}
                />
                {!edit?
                <ListItemText
                primary={value}
                sx={{flex:3}}
                />:
                <TextField
                type={type}
                inputProps={{
                    min:0
                }}
                sx={{flex:3}}
                multiline={multiline}
                value={value}
                onChange={(e)=>setUpdate((prev)=>({...prev,[key]:e.target.value}))}
                />
                }
            </ListItem>
        // <StyleListItem
        // key={key}
        // title={title}
        // value={value}
        // onEdit={(e)=>{}}
        //     setUpdate((prev)=>({...prev,[key]:e.target.value}))
        // }}
        // multiline={multiline}
        // type={type}
        // />
        )})}
        {/* array value */}
        <ListItem
        sx={{
            alignItems:'flex-start'
        }}
        >
            <ListItemText
            primary='特色'
            sx={{
                flex:1,
            }}
            />
            <List
            sx={{
                flex:3,
            }}
            >
                {
                    [
                        ...(
                            update?.feature||
                            data?.[tabIndex]?.feature||
                            []
                            )
                    ]
                    ?.map((val,index)=>{
                        return(
                            <ListItem
                            divider
                            key={index}
                            sx={{pl:0}}
                            >
                                <span
                                style={{
                                    wordWrap:'keep-all',
                                    whiteSpace:'nowrap'
                                }}
                                >
                                    {
                                        index+' : '
                                    }
                                </span>
                                {!edit?
                                <ListItemText
                                primary={val}
                                />:
                                <>
                                <TextField
                                hiddenLabel
                                value={val}
                                onChange={(e)=>handleFeature('edit',index,e.target.value)}
                                />
                                <IconButton
                                onClick={()=>handleFeature('delete',index)}
                                children={<Delete/>}
                                />
                                </>
                                }
                            </ListItem>
                        )
                    })
                }
                {edit&&
                <ListItem>
                    <Button
                    startIcon={<Add/>}
                    children='新增'
                    onClick={()=>handleFeature('add')}
                    />
                </ListItem>
                }
            </List>
        </ListItem>
        {/* photo */}
        <CustomImageList
        photo={data?.[tabIndex]?.photo||[]}
        refetch={refetch}
        onUpdate={async(val)=>await handleUpdate({photo:val})}
        onDelete={async(ids)=>{
            await axios.delete(process.env.REACT_APP_API_ADDRESS+'/setting/room/'+data?.[tabIndex]?.roomNumber+'/photo/?',
            {
                withCredentials:true,
                params:{id:ids}
            })
        }}
        />
    </List>
    <ToTopButton
    triggerHeight={30}
    />
    </div>
  )
}

export default Room