import { Button, Dialog, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const DoubleCheckButton = ({icon,actionName,action,children,sx}) => {
    const [open,setOpen] = React.useState(false)
    const childrenRef = React.useRef()
    React.useEffect(()=>{
      if(childrenRef.current){
      const open = () => setOpen(true)
      const element = childrenRef.current
      element.addEventListener('click',open)
      return(()=>{
        element.removeEventListener('click',open)
      })
      }
    },[children])
  return (
    <>
        <>
        {
        icon?
        <IconButton
        // {...buttonProps}
        onClick={(e)=>{
          e.stopPropagation()
          setOpen(true)
        }}
        children={icon}
        />:
        children&&
        React.Children.map(children,(child)=>
        React.cloneElement(child,{...child.props,ref:childrenRef})
        )
        }
        </>
        <Dialog
        open={open}
        onClose={()=>setOpen(false)}
        // sx={{
        //   display:'flex',
        //   flexDirection:'column',
        //   gap:1,
        //   padding:5
        // }}
        >
          <Box
          sx={{
            display:'flex',
            flexDirection:'column',
            gap:1,
            p:2
          }}
          >
            <Button
            onClick={(e)=>{
                e.stopPropagation()
                action()
                setOpen(false)
            }}
            children={actionName}
            variant='contained'
            sx={sx}
            />
            <Button
            onClick={(e)=>{
              e.stopPropagation()
              setOpen(false)
            }}
            variant='contained'
            children='返回'
            sx={sx}
            />
          </Box>
        </Dialog>
    </>
  )
}

export default DoubleCheckButton