import { ListSubheader } from '@mui/material'
import React from 'react'

const StyledSubheader = ({children,...props}) => {
  return (
    <ListSubheader
    disableSticky
    {...props}
    >
        {children}
    </ListSubheader>
  )
}

export default StyledSubheader