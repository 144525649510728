import React from 'react'
import { useFetch } from '../../hook/useFetch'
import { addDays, format, subDays } from 'date-fns'
import { Button, ButtonGroup, Divider, List, ListItem, ListItemButton, ListItemText, Paper, Tab, Tabs, Typography } from '@mui/material'
import { Box } from '@mui/system'
import './mealArrange.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { zhCN } from 'date-fns/locale'
import StyledSubheader from '../../component/styledSubheader/StyledSubheader'
import { useQuery } from '../../hook/useQuery'
import { UserContext } from '../../context/UserContext'

const Total = ({orders,time}) => {
    const total = getTotal(orders,time)
    function getTotal (orders,time) {
        const result = orders.reduce((acc,cur)=>{
                            if(!cur?.breakfast){return acc}
                            for(const arrange of cur.breakfast){
                                if(!time.includes(arrange.time)){continue}
                                for(const drink of arrange.drink){
                                    const name = drink.name
                                    const quantity = drink.quantity
                                    acc[name] = (acc?.[name]||0)+quantity
                                }
                            }
                            return acc
                        },{})
        return result
    }
    return(
        <List>
                {Object.keys(total).map((key)=>{
                    const quantity = total[key]
                    return(
                        <ListItem
                        key={key}
                        divider
                        >
                            <ListItemText
                            primary={`${key} x ${quantity}`}
                            />
                        </ListItem>
                    )
                })
                }
                {/* <ListItem>
                    <ListItemText
                    primary={`總共: ${Object.values(total).reduce((acc,cur)=>acc+=cur,0)} 樣`}
                    />
                </ListItem> */}
            </List>
    )
}
const Order = ({order,time,unplanned,navigateToOrder=()=>{}}) => {
    return(
        <Paper
        sx={{
            width:'fit-content',
        }}
        >
            <ListItem
            key={order._id}
            sx={{
                p:0
            }}
            >
            <ListItemButton
            onClick={()=>navigateToOrder(order._id)}
            sx={{
                flexDirection:'column',
                alignItems:'start'
            }}
            >
            <ListItemText
            primary={order.orderer}
            secondary={order.roomNumber.join('/')}
            />
            {
            !unplanned && 
            order.breakfast
            .filter((i)=>time.includes(i.time))
            .map((i,index1)=>{
            return(
            <Paper
            key={'breakfast-'+index1}
            sx={{
                mt:1,
                p:1
            }}
            >
            <List
            subheader={
            <StyledSubheader
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    mb:1,
                    gap:1,
                    '*':{
                        lineHeight:'100%'
                    }
                }}
            >
                <span>
                    {'桌號：'+i.table}
                </span>
                <span>
                    {'時間：'+i.time}
                </span>
            </StyledSubheader>
            }
            >
                {
                    i.drink.map((j,index2)=>{
                    return(
                    <ListItem
                    key={'drink-'+index2}
                    sx={{
                        pt:0,
                        pb:0,
                    }}
                    >
                        <ListItemText
                        primary={j.name+' x '+j.quantity}
                        sx={{
                            m:0,
                        }}
                        />
                    </ListItem>
                    )
                    })
                }
            </List>
            </Paper>
            )}
            )
            }
            <>
            </>
            </ListItemButton>
            </ListItem>
            </Paper>
    )
}
const MealArrange = () => {
    const {user} = React.useContext(UserContext)
    const isAdmin = user.isAdmin
    const today = new Date(new Date().toDateString())
    const url = process.env.REACT_APP_API_ADDRESS+'/setting/orderlist/'
    const query = useQuery()
    const fetchQuery = React.useMemo(()=>(
        {
        type:'breakfast',
        field:['_id','breakfast','roomNumber','orderer'],
        startDate:query.get('startDate')?
                        subDays(new Date(query.get('startDate')+'T16:00:00.000Z'),1):
                        today
        }
    ),[query])
    const navigate = useNavigate()
    const [error,setError] = React.useState({})
    const [unplannedOrder,setUnplannedOrder] = React.useState([])
    const [plannedOrder,setPlannedOrder] = React.useState([])
    const [isMobile,setIsMobile] = React.useState()
    const tabDate = React.useMemo(()=>{
        let days = []
        const count = 7
        for(let i=0;i<count;i++){
            days.push(addDays(today,i))
        }
        return days
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const {data} = useFetch(url,fetchQuery)
    const timePlan = [
        '08:00',
        '08:30',
        '09:00'
    ]
    const [time,setTime] = React.useState(timePlan)
    // function handleError(id,message){
    //     setError((prev)=>{
    //         let _error = {...prev}
    //         if(_error?.[id]){
    //             _error[id].push(message)
    //         }else{
    //             _error[id] = [message]
    //         }
    //         _error[id] = Array.from(new Set(_error[id]))
    //         return _error
    //     })
    // }
    function initialize () {
        const {planned,unplanned} = data.reduce((acc,cur)=>{
                    const {
                        breakfast,
                        ...other
                    } = cur
                    if(breakfast.length>0){
                        let time = []
                        let error
                        const newBreakfast = breakfast.map((i)=>{
                            if(!i.time){
                                error = true
                            }
                            time.push(i.time)
                            return ({
                                drink:i.drink.reduce((acc,cur)=>{
                                    if(cur.name==='熱咖啡'){
                                        let drink1 = {
                                            name:'熱咖啡-大',
                                            quantity:parseInt(cur.quantity/2)
                                        }
                                        let drink2 = {
                                            name:'熱咖啡-小',
                                            quantity:cur.quantity%2
                                        }
                                        return acc.concat([drink1,drink2].filter(d=>d.quantity))
                                    }else{
                                        return acc.concat([cur]).filter(d=>d.quantity)
                                    }
                                },[]),
                                time:i.time,
                                table:i.table
                            })
                        })
                        if(error){
                            acc.unplanned.push({...other})
                        }else{
                            acc.planned.push({...cur,breakfast:newBreakfast,time})
                        }
                    }else{
                        acc.unplanned.push({...other})
                    }
                    return acc
                },{'planned':[],'unplanned':[]})
                setUnplannedOrder(unplanned)
                setPlannedOrder(planned)
    }
    React.useEffect(()=>{
        initialize()
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[data])
    React.useEffect(()=>{
        handleIsMobile()
        window.addEventListener('resize',handleIsMobile)
        return(()=>window.removeEventListener('resize',handleIsMobile))
        function handleIsMobile (){
            let _isMobile = false
            if(window.innerWidth < 640){
                _isMobile = true
            }
            setIsMobile(_isMobile)
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    function navigateToOrder (orderId) {
        if(!isAdmin){return}
        navigate(`/order/${orderId}`,{state:{mode:2}})
    }
  return (
    <div
    className='meal-arrange'
    >
        <Paper
        sx={{
            width:1,
            position:'sticky',
            top:0,
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            p:1,
            bgcolor:'white',
            zIndex:999
        }}
        >
            <Paper>
                <List>
                    {Object.keys(error).map((orderId)=>{
                        const message = error[orderId].join('/')
                        return(
                            <ListItem
                            key={orderId}
                            sx={{
                                // width:'fit-content'
                            }}
                            >
                                <ListItemButton
                                sx={{
                                    borderRadius:4,
                                    border:'2px solid',
                                    borderColor:(theme)=>theme.palette.error.main
                                }}
                                onClick={()=>navigateToOrder(orderId)}
                                >
                                    <ListItemText
                                    primary={`訂單：${orderId}`}
                                    secondary={`錯誤：${message}`}
                                    />
                                </ListItemButton>
                            </ListItem>
                        )
                    })
                        
                    }
                </List>
            </Paper>
            <Tabs
            sx={{
                width:1,
                borderBottom: 1, 
                borderColor: 'divider'
            }}
            value={fetchQuery.startDate.getTime()}
            // centered
            variant="scrollable"
            scrollButtons={true}
            allowScrollButtonsMobile
            >
                {tabDate.map((date)=>{
                    return(
                    <Tab
                    key={date}
                    value={date.getTime()}
                    label={format(date,'yy/MM/dd(iiii)',{locale:zhCN})}
                    onClick={()=>{
                        navigate(`?startDate=${format(date,'yyyy-MM-dd')}`,{replace:true})
                        // setQuery((prev)=>({...prev,startDate:date,endDate:date}))
                    }}
                    />
                    )
                }
                )}
            </Tabs>
            <Box
            sx={{
                p:1,
                display:'flex',
                gap:1,
                width:'100%'
            }}
            >
                {
                    timePlan.map((i)=>{
                        const included = time.includes(i)
                    return(
                    <Button
                    key={i}
                    variant={included?'contained':'outlined'}
                    children={i}
                    onClick={()=>setTime((prev)=>{
                        return included?prev.filter((j)=>j!==i):prev.concat([i])
                    })}
                    />
                    )
                    }
                    )
                }
            </Box>
        </Paper>
        <Box
        sx={{
            width:1,
            display:'flex',
            flexDirection:isMobile?'column':'normal'
        }}
        >
        <Paper
        sx={{
            flex:2,
            p:1,
            m:1
        }}
        >
            <StyledSubheader children='尚未安排訂單'/>
            <List
            sx={{
                display:'flex',
                flexDirection:'flex-wrap'
            }}
            >
                {unplannedOrder.map((order)=>{
                    return(
                        <Order
                        key={order._id}
                        order={order}
                        unplanned={true}
                        navigateToOrder={navigateToOrder}
                        />
                    )
                })
                }
            </List>
            <StyledSubheader children='已經安排訂單'/>
            <List
            sx={{
                display:'flex',
                flexDirection:'flex-wrap'
            }}
            >
                {
                plannedOrder.filter(i=>time.some(t=>i.time.includes(t))).map((order)=>{
                    return(
                        <Order
                        key={order._id}
                        order={order}
                        time={time}
                        navigateToOrder={navigateToOrder}
                        />
                    )
                })
                }
            </List>
            </Paper>
            <Paper
                sx={{
                    flex:1,
                    p:1,
                    m:1
                }}
            >
            <List
            subheader={<StyledSubheader children='總共'/>}
            >
              <Total
                orders={plannedOrder}
                time={time}
                />
            </List>
            </Paper>
        </Box>
    </div>
  )
}

export default MealArrange