import { ArrowBack, Edit } from '@mui/icons-material'
import { Button, Dialog, Divider, Grid, List, ListItem, ListItemText, Paper, Switch, TextField, Typography } from '@mui/material'
import axios from 'axios'
import { format } from 'date-fns'
import { zhCN } from 'date-fns/locale'
import React from 'react'
import OrderView from '../orderView/OrderView'

const CalendarList = ({calendar,refetch}) => {
    const [open,setOpen] = React.useState(null)
    const EditRoomCalendar = ({theDate}) => {
        const [edit,setEdit] = React.useState(false)
        const [update,setUpdate] = React.useState(null)
        const handleUpdate = async () => {
            try {
                if(update){
                    await axios.put(
                                    process.env.REACT_APP_API_ADDRESS+
                                    '/setting/room/'+
                                    theDate.roomNumber+
                                    '/calendar',
                                    [{date:theDate.date,...update}],
                                    {withCredentials:true}
                                    )
                    setUpdate(null)
                }
                setEdit(false)
                refetch()
            } catch (error) {
                console.log(error)
            }
        }
        return(
            <>
            <Paper
            sx={{
                display:'flex',
                justifyContent:'space-between',
                mb:1
            }}
            >
                <Button
                startIcon={<ArrowBack/>}
                children='關閉'
                onClick={()=>{
                    setOpen(null)
                    setEdit(false)
                    setUpdate(null)
                }}
                />
                <Button
                endIcon={<Edit/>}
                children={edit?'更新':'編輯'}
                onClick={()=>{
                    if(edit){
                        handleUpdate()
                    }else{
                        setEdit(true)
                    }
                }}
                />
            </Paper>
            <List>
                <ListItem
                secondaryAction={
                    theDate?.hasOrder&&
                    <Button
                    variant='contained'
                    children='前往訂單'
                    onClick={()=>{
                        setOpen((prev)=>({...prev,orderView:theDate.orderId}))
                    }}
                    />
                }
                >
                    <ListItemText
                    primary={format(theDate.date,'yyyy/MM/dd (iiii)',{locale:zhCN})}
                    secondary={theDate.roomNumber+' 房'}
                    />
                </ListItem>
                <Divider/>
                {
                [
                {label:'關閉房間',key:'isClosed'},
                {label:'房間價格',key:'price'},
                {label:'可否加人',key:'canAddPerson'},
                {label:'加人價格',key:'addPersonPrice'},
                ].map((item,index)=>{
                    const value = theDate[item.key]
                    const isBoolean = typeof value === 'boolean'
                    return(
                    <div
                    key={index}
                    >
                    <ListItem
                    alignItems='flex-start'
                    sx={{
                    }}
                    >
                        <ListItemText
                        primary={item.label}
                        />
                        {
                        isBoolean?
                        <Switch
                        id={item.label}
                        disabled={!edit}
                        defaultChecked={value}
                        onChange={(e)=>setUpdate((prev)=>({...prev,[item.key]:e.target.checked}))}
                        />:
                            edit?
                            <TextField
                            size='small'
                            hiddenLabel
                            defaultValue={value}
                            onChange={(e)=>setUpdate((prev)=>({...prev,[item.key]:e.target.value}))}
                            />:
                            <Typography
                            children={value}
                            sx={{
                                p:1
                            }}
                            />
                        }
                    </ListItem>
                    <Divider/>
                    </div>
                    )
                })
                }
            </List>
            </>
        )
    }
  return (
    <div>
        <Dialog
        fullScreen
        open={open?true:false}
        >
            {
            open&&
            <>
            {
            open.orderView?
            <OrderView
            orderId={open.orderView}
            close={()=>{
                setOpen((prev)=>({...prev,orderView:null}))
            }}
            />:
            <EditRoomCalendar
            theDate={
                {
                    date:calendar[open.theDate[0]].date,
                    ...calendar[open.theDate[0]].room[open.theDate[1]]
                }
            }
            />
            }
            </>
            }            
        </Dialog>
         <List>
            {calendar.map((item,dateIndex)=>{
                const date = new Date(item.date)
                return(
                    <ListItem
                    divider
                    key={item.date}
                    alignItems='flex-start'
                    sx={{
                        gap:1
                    }}
                    >
                     <ListItemText
                     primary={format(date,'yy/MM/dd/(i)',{locale:zhCN})}
                     primaryTypographyProps={{
                        sx:{
                            wordBreak:'break-all'
                        }
                     }}
                     secondary={item.dateType}
                     sx={{
                        flex:1
                     }}
                     />
                     {
                        (item.dateType !== 'closed' ||
                        item.room.some(({hasOrder})=>hasOrder)) &&
                     <Grid
                     container
                     sx={{
                        flex:3,
                        gap:1
                    }}
                     >
                        {
                            item.room.map((room,roomIndex)=>{
                                const schema = [
                                    {
                                    key:'hasOrder',
                                    text:'已訂',
                                    buttonColor:'warning',
                                    bgColor:'warning.main'
                                    },
                                    {
                                    key:'isClosed',
                                    text:'關閉',
                                    buttonColor:'error',
                                    bgColor:'error.main'
                                    },
                                    {
                                    key:'default',
                                    text:room.price,
                                    buttonColor:'primary',
                                    bgColor:'primary.main'
                                    }
                                ].find((v)=>{
                                    return room?.[v.key]||v.key==='default'
                                })
                                return(
                                    <Grid 
                                    key={room.roomNumber}
                                    item
                                    sx={{}}
                                    >
                                        <Button
                                        variant='outlined'
                                        color={schema.buttonColor}
                                        onClick={()=>setOpen({
                                            orderView:null,
                                            theDate:[dateIndex,roomIndex]
                                        })}
                                        sx={{
                                            display:'flex',
                                            flexDirection:'column',
                                            overflow:'hidden',
                                            p:0
                                        }}
                                        >
                                        <Typography
                                        color='white'
                                        sx={{
                                            width:1,
                                            bgcolor:schema.bgColor
                                        }}
                                        children={room.roomNumber}
                                        />
                                        <Typography
                                        children={schema.text}
                                        />
                                        </Button>
                                    </Grid>
                                )
                            })
                        }
                     </Grid>
                    }
                    </ListItem>
                )
            })
            }
         </List>
    </div>
  )
}

export default CalendarList